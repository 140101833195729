import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { setCessionSIPS } from "../../actions/contracts";
import ContractNoCesionDialog from "./Dialog";
import Settings from "../../settings";

import { Button, Checkbox, FormControlLabel } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { i18n } from "../../config";


const mapStateToPros = (state) => ({
   token: state.auth.token,
});


class ContractNoCesion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: props.contract.no_cession_sips,
        };
    }

    render() {
        const element = this.props.contract;

        const dialog = (
            <ContractNoCesionDialog contract={ element } open={ this.props.open } changeDialogSipsOpen={this.props.changeDialogSipsOpen.bind(this)} />
        );

        const NO_CESION_STATUS = {
            requested: { disabled: true, checked: false, label: i18n.t('common:text.sips_requested') },
            active: { disabled: true, checked: true, label: i18n.t('common:text.sips_active') },
            unactive: { disabled: false, checked: false, label: i18n.t('common:text.sips_disabled') },
        };
        const current_cesion = (this.state.status) ? this.state.status : "unactive";

      return (
        <div>
          {dialog}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={true}
                  onChange={this.handleCheck}
                  checked={NO_CESION_STATUS[current_cesion]?.checked}
                  checkedIcon={<VisibilityOff />}
                  icon={<Visibility />}
                />
              }
              label={i18n.t('common:text.sips_title')}
            />
            {!_.get(Settings, "contract.horizontalButtons", false) &&
              <Button
                color={'primary'}
                variant={'text'}
                disabled={NO_CESION_STATUS[current_cesion]?.disabled || !this.props.contract.atr_available}
                title={i18n.t('common:text.sips_edit_data')}
                onClick={() => this.props.changeDialogSipsOpen(true)}
              >
                {NO_CESION_STATUS[current_cesion]?.label}
              </Button>
            }
          </div>
          {
            this.state.status === 'requested' &&
            <div style={{ fontSize: '12px' }}>
              <em>{i18n.t('common:text.sips_process_already')}</em>
            </div>
          }
        </div>
      );
    }
}

export default connect(mapStateToPros, { setCessionSIPS })(ContractNoCesion);
