
import { Button, CircularProgress } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const DownloadButton = ({ invoiceId, type, onDownload }) => {
  const downloadStatus = useSelector((state) => type === "gas" ? state.invoicesGas.download_status[invoiceId] : state.invoices.download_status[invoiceId]);
  const { t } = useTranslation();

  return (
    <Button
      title={"Download invoice"}
      color={'primary'}
      variant={'contained'}
      disabled={downloadStatus}
      onClick={() => onDownload(invoiceId)}
      style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
    >
      {downloadStatus ? <CircularProgress size={25} /> : <GetApp />}
      {t('common:text.invoices_view_download_button')}
    </Button>
  )
}