import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import { i18n } from "../../config";

import PowerField from "../form-fields/PowerField";
import CheckboxField from "../form-fields/CheckboxField";
import FileUploadField from "../form-fields/FileUploadField";
import AccessTariffSelectField from "../form-fields/AccessTariffSelectField";
import ElectricityProductSelectField from "../form-fields/ElectricityProductSelectField";

import { CircularProgress, Button, Grid, Box, Typography, Paper } from "@material-ui/core"
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Settings from "../../settings";
import RadioGroupField from "../form-fields/RadioGroupField";
import CouponField from "../form-fields/CouponField";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newElectricityContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormAboutContract = (props) => {

  const [tariffName, setTariffName] = useState(_.get(props, 'accessTariffName', ''));

  const handleGoBack = (values) => {
    if (props.onGoBack) {
      props.onGoBack(values);
    }
  };

  const handleSubmit = async (values) => {
    props.submitAboutContractData(values);

    if (props.onSubmit) {
      await props.onSubmit(values);
    }
  };

  const handleAccessTariffSelectUpdate = async (value, tariff_name) => {
    props.setAccessTariff(value, tariff_name);
    setTariffName(tariff_name);
  };

  const handlePowerUpdate = async (value) => {
    props.setPower(value);
  };

  const handlePower2Update = async (value) => {
    props.setPower2(value);
  };

  const handlePower3Update = async (value) => {
    props.setPower3(value);
  };

  const handlePower4Update = async (value) => {
    props.setPower4(value);
  };

  const handlePower5Update = async (value) => {
    props.setPower5(value);
  };

  const handlePower6Update = async (value) => {
    props.setPower6(value);
  };

  const handleSelfconsUpdate = async (value) => {
    props.setSelfcons(value);
  };

  const handleSelfconsAttachmentUpdate = async (value) => {
    props.setSelfconsAttachment(value);
  };

  const handleCouponChange = async (value) => {
    console.log(value);
    props.setCoupon(value);
  };

  const sixPowers = () => {
    //return (tariffName.charAt(0) === "3")
    return (props.power > 15 || props.power2 > 15)
  }

  const allPowersFull = () => {
    if (sixPowers()){
      return !!(props.power && props.power2 && props.power3 &&
              props.power4 && props.power5 && props.power6)
    } else {
      return !!(props.power && props.power2)
    }
  }

  const theme = useTheme();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      { _.get(Settings, "newContract.sectionHeaders", false) &&
        <Box m={2} display={"flex"} style={{marginLeft: 0}}>
          <WbIncandescentIcon fontSize="large" color="secondary" style={{padding: 10}}/>
          <Typography variant="h4" style={{alignSelf: "center"}}>{i18n.t('common:text.contractation_about_contract')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          power: props.power,
          power2: props.power2,
          power3: props.power3,
          power4: props.power4,
          power5: props.power5,
          power6: props.power6,
          accessTariff: props.accessTariff,
          selectedProduct: props.selectedProduct,
          selfcons: props.selfcons,
          selfconsAttachment: props.selfconsAttachment,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.power) {
            errors.power = i18n.t('common:text.required_field');
          }

          if (!values.power2) {
            errors.power2 = i18n.t('common:text.required_field');
          }

          if (sixPowers() && !values.power3) {
            errors.power3 = i18n.t('common:text.required_field');
          }

          if (sixPowers() && !values.power4) {
            errors.power4 = i18n.t('common:text.required_field');
          }

          if (sixPowers() && !values.power5) {
            errors.power5 = i18n.t('common:text.required_field');
          }

          if (sixPowers() && !values.power6) {
            errors.power6 = i18n.t('common:text.required_field');
          }

          if (!Number.isInteger(_.get(values, 'accessTariff', false))) {
            errors.accessTariff = i18n.t('common:text.required_field');
          }
          
          if (!values.selectedProduct) {
            errors.selectedProduct = i18n.t('common:text.required_field');
          }

          if (_.get(Settings, 'newContract.selfconsRadioGroupVariant', false) && values.selfcons === null) {
            errors.selfcons = i18n.t('common:text.required_field');
          }

          if (_.get(Settings, 'newContract.selfcons', false) && values.selfcons &&
                _.get(Settings, 'newContract.requireSelfconsDocumentation', true) && !values.selfconsAttachment) {
            errors.selfconsAttachment = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {
              _.get(Settings, 'newContract.selfconsShowSellMessage', true) &&
                <Typography style={{fontWeight: "bold"}}>
                  {i18n.t('common:text.contractation_selfcons_message')}
                </Typography>
            }
            <>
              <Grid container spacing={3}>
                {!props.sipsData &&
                  <Grid item sm={12} md={6}>
                    <Box>
                      <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                        <Typography variant="h6" color="primary">{i18n.t('common:text.contractation_current_contract_data')}</Typography>
                      </Box>
                      <Typography variant="body2">{i18n.t('common:text.contractation_current_power_warning')}</Typography>
                    </Box>
                    <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0} style={{marginBottom: 15, marginTop: 15}}>
                      <Grid container spacing={3} xs={12}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="power"
                            label={i18n.t('common:text.contractation_power')}
                            component={PowerField}
                            onUpdate={handlePowerUpdate}
                            style={{width: "100%"}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="power2"
                            label={i18n.t('common:text.contractation_power2')}
                            component={PowerField}
                            onUpdate={handlePower2Update}
                            style={{width: "100%"}}
                          />
                        </Grid>
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power3"
                              label={i18n.t('common:text.contractation_power3')}
                              component={PowerField}
                              onUpdate={handlePower3Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power4"
                              label={i18n.t('common:text.contractation_power4')}
                              component={PowerField}
                              onUpdate={handlePower4Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power5"
                              label={i18n.t('common:text.contractation_power5')}
                              component={PowerField}
                              onUpdate={handlePower5Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        {sixPowers() &&
                          <Grid item xs={12} sm={6}>
                            <Field
                              name="power6"
                              label={i18n.t('common:text.contractation_power6')}
                              component={PowerField}
                              onUpdate={handlePower6Update}
                              style={{width: "100%"}}
                            />
                          </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="accessTariff"
                            component={AccessTariffSelectField}
                            power={{
                              power: props.power, power2: props.power2, power3: props.power3,
                              power4: props.power4, power5: props.power5, power6: props.power6
                            }}
                            initialTariff={props.accessTariff?.id}
                            onSelectUpdate={handleAccessTariffSelectUpdate}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                }
                { _.get(Settings, "newContract.selfcons", true) &&
                  <Grid item sm={12} md={6}>
                    <Paper style={{padding: 15}}>
                      { _.get(Settings, "newContract.selfconsRadioGroupVariant", false) ?
                        <Field
                          name="selfcons"
                          label={<Typography variant="h6" color="primary">{i18n.t('common:text.contractation_selfcons')}</Typography>}
                          component={RadioGroupField}
                          onUpdate={handleSelfconsUpdate}
                          radioElements={
                            [
                              {label: i18n.t("common:text.generic_yes"), val: true, style: {width: "25%"}},
                              {label: i18n.t("common:text.generic_no"), val: false, style: {width: "25%"}}
                            ]
                          }
                          row
                        />
                      :
                        <Field
                          name="selfcons"
                          label={<Typography variant="h6" color="primary">{i18n.t('common:text.contractation_selfcons')}</Typography>}
                          component={CheckboxField}
                          onUpdate={handleSelfconsUpdate}
                        />
                      }
                      {values.selfcons && (
                        <Field
                          name="selfconsAttachment"
                          component={FileUploadField}
                          onUpdate={handleSelfconsAttachmentUpdate}
                          min={1}
                          max={3}
                          label={i18n.t('common:text.contractation_selfcons_document')}
                          hint={i18n.t('common:text.contractation_selfcons_helper')}
                          anotherLabel={i18n.t('common:text.contractation_selfcons_add')}
                          removeLabel={i18n.t('common:text.remove')}
                        />
                      )}
                    </Paper>
                  </Grid>
                }
              </Grid>
            </>
            {props.accessTariff && allPowersFull() &&(
              <>
                {Settings?.features?.promotionalCode && 
                  <>
                    <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                      <Typography variant="h6" color="primary">
                        {i18n.t('common:text.contractation_promotional_code')}
                      </Typography>
                      <Typography variant="body2" style={{marginBottom: 15}}>
                        {i18n.t('common:text.contractation_promotional_detail')}
                      </Typography>
                    </Box>

                    <Field
                      name="promocode"
                      component={CouponField}
                      onChange={handleCouponChange}
                    />
                  </>
                }

                <Box m={1} style={{marginLeft: 0}} color="textSecondary">
                  <Typography variant="h6" color="primary">
                    {i18n.t('common:text.contractation_new_pricelist')}
                  </Typography>
                  <Typography variant="body2" style={{marginBottom: 15}}>
                    {i18n.t('common:text.contractation_current_pressure_warning')}
                  </Typography>
                </Box>
                <Box ml={matchesBreakpointMd ? 5 : 0} mr={matchesBreakpointMd ? 5 : 0}>
                  <Field
                  name="selectedProduct"
                  component={ElectricityProductSelectField}
                  />
                </Box>
              </>
            )}
            <div style={{ marginTop: 25 }}>
              <Button
                variant={'text'}
                onClick={() => handleGoBack(values)}
                style={{ marginRight: 12 }}
                disabled={submitting}
              >
                {i18n.t('common:text.contractation_previous')}
              </Button>
              <Button
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={submitting}
              >
                {submitting ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.contractation_next')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAboutContract);
