import React, { useEffect, useState } from "react";
import { validateCoupon } from "../../actions/newElectricityContract";

import { TextField, FormHelperText, useTheme, useMediaQuery, Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CouponField = ({
  label,
  input,
  onChange,
  defaultValue  = ''
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const {valid, submitted, loading, alreadyApplied} = useSelector(state => state.newElectricityContract.coupon);
  const promotionalCode = useSelector(state => state.newElectricityContract.promotionalCode);
  const [value, setValue] = useState(defaultValue);

  const handleChange = event => {
    // Inner component
    setValue(event.target.value);

    // Outter form, store in redux
    onChange && onChange(event.target.value);

    // React-Final-Form
    input.onChange && input.onChange(event.target.value);
  }

  const applyChange = () => {
    if(value) {
      dispatch(validateCoupon(value));
    }
  }

  useEffect(() => {
    if(valid) {
      setValue('');
    }
  }, [valid]);

  return (
    <>
      <Grid container spacing={1} 
        style={{width: matchesBreakpointMd ? "50%" : "100%", marginBottom: "10px"}}
      >
        <Grid item xs={10}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={label}
            value={value}
            onChange={handleChange}
            error={submitted && !loading && !valid}
          />

          {submitted && !loading && !valid && !alreadyApplied && 
            <FormHelperText error={true}>
              {t("common:text.contractation_promotional_invalid")}
            </FormHelperText>
          }

          {submitted && !loading && !valid && alreadyApplied && 
            <FormHelperText error={true}>
              {t("common:text.contractation_promotional_already_applied")}
            </FormHelperText>
          }

        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => applyChange()}
          >
            {t("common:text.contractation_promotional_apply")}
        </Button>
        </Grid>
      </Grid>
      
      {promotionalCode.map((promocode, idx) => (
        <p key={idx}>
          {t("common:text.contractation_promotional_applied", {promocode})}
        </p>
      ))}
    </>
  )
};

export default CouponField;