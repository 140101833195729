import _ from "lodash";
import {
    FETCH_INVOICES_MULTIPOINT_REQUEST,
    FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST,
    RECEIVE_INVOICES_MULTIPOINT,
    RECEIVE_INVOICES_MULTIPOINT_FILTER,
    EXPORT_INVOICE_MULTIPOINT_REQUEST, 
    EXPORT_INVOICE_MULTIPOINT_DONE, 
    MESSAGE_INVOICE_MULTIPOINT
} from '../constants/index'
import {
    data_fetch_api_resource,
    data_download_api_resource
} from '../utils/http_functions'
import {
    parseJSON
} from '../utils/misc'
import {
    logoutAndRedirect
} from './auth'
import saveAs from 'file-saver';
import Settings from "../settings";
import { i18n } from "../config";


export function fetchInvoicesMultipointRequest(initial) {
    const message = (initial)?null:i18n.t('common:text.invoices_updating_list');

    return {
        type: FETCH_INVOICES_MULTIPOINT_REQUEST,
        payload: {
            message,
        },
    };
}

export function fetchInvoicesMultipointFilterRequest() {
    return {
        type: FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST
    };
}

export function receiveInvoicesMultipoint(response, initial, filters, errors=null) {
    const message = (initial)?null:i18n.t('common:text.invoices_updated_list');
    return {
        type: RECEIVE_INVOICES_MULTIPOINT,
        payload: {
            response,
            message,
            filters,
            errors
        },
    };
}

export function receiveInvoicesMultipointFilter(payload) {
    return {
        type: RECEIVE_INVOICES_MULTIPOINT_FILTER,
        payload
    };
}

export const messageInvoice = message => {
    return {
        type: MESSAGE_INVOICE_MULTIPOINT,
        payload: {
            message
        }
    }
}

export function fetchFilters(token) {
    return (dispatch) => {
        dispatch(fetchInvoicesMultipointFilterRequest());

        const resource = "filter/params?fields=cups,addresses,contracts,vats";

        data_fetch_api_resource(token, resource, 2)
        .then(parseJSON)
        .then((response) => {
            dispatch(receiveInvoicesMultipointFilter(response));
        })
        .catch(error => {
            dispatch(receiveInvoicesMultipointFilter({}));
            if (error.status === 401) {
                dispatch(logoutAndRedirect(error));
            }
        });
    }
} 

export function fetchInvoicesMultipoint(token, initial = false, offset = 0, filters = '', limit = 100) {
    return (dispatch, getState) => {
        // const filtersCache = getState().invoices.filters?.cache;
        // const foundFilter = filtersCache[filters];
        dispatch(fetchInvoicesMultipointRequest(initial));

        // if (foundFilter && foundFilter.items.length < limit + offset) {

        //     dispatch(receiveInvoicesMultipoint(foundFilter, initial, filters));
            
        // } else {
            const resource = `invoices_multipoint/?offset=${offset}&limit=${limit}&filter=[${filters}]`;

            data_fetch_api_resource(token, resource, 2)
            .then(parseJSON)
            .then((response) => {
                dispatch(receiveInvoicesMultipoint(response, initial, filters));
            })
            .catch((error) => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                } else {
                    dispatch(receiveInvoicesMultipoint([], initial, filters, error));
                }
            });
        // }
    };
}

export function exportInvoiceRequest(invoice) {
    return {
        type: EXPORT_INVOICE_MULTIPOINT_REQUEST,
        payload: {
            invoice,
            message: null,
        },
    };
}

export function exportInvoiceDone(invoice) {
    return {
        type: EXPORT_INVOICE_MULTIPOINT_DONE,
        payload: {
            invoice
        },
    };
}

export function exportInvoice(token, invoice) {
    return (dispatch) => {
        dispatch(exportInvoiceRequest(invoice));

        if (_.get(Settings, "invoices.downloadInNewTab", false)) {
            let newTab = window.open(i18n.t('common:url.loading'), '_blank');
            return data_download_api_resource(token, "invoices_multipoint/" + invoice + "/pdf/", 2)
                .then(response => response.data)
                .then(blob => URL.createObjectURL(blob))
                .then(anUrl => {
                    newTab.location.href = anUrl;
                    console.log("response.data", response.data)
                    console.log("filename[1]", blob)
                    URL.revokeObjectURL(i18n.t('common:url'));
                })
                .catch(error => {
                    if (error.status === 401) {
                        dispatch(logoutAndRedirect(error));
                    }
                })
                .finally(() => {
                    dispatch(exportInvoiceDone(invoice));
                });
        } else {
            data_download_api_resource(token, `invoices_multipoint/${ invoice }/pdf/`, 2)
              .then(response => {
                  const filename = response.headers["content-disposition"].split("=");
                  dispatch(messageInvoice(i18n.t('common:text.invoices_download_ready')));
                  console.log(response.data)
                  saveAs(response.data, filename[1]);
              })
              .catch(error => {
                  if (error.status === 401) {
                      dispatch(logoutAndRedirect(error));
                  }
              })
              .finally(() => {
                  dispatch(exportInvoiceDone(invoice));
              });
        }
    };
}
