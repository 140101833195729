import React from "react";
import LegacyPowerField, { powerValid } from "../PowerField";
import FlexRow from "../FlexRow";
import { FormHelperText } from "@material-ui/core"

const TextField = ({ input: { onChange, value }, label, readOnly = false, onUpdate, meta, ...rest }) => {
  return (
    <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
      <LegacyPowerField
        value={value}
        onValueChange={({ value: v }) => {
          onChange({ target: { value: v } }, null, v);
          if (typeof onUpdate === "function") {
            onUpdate(v);
          }
        }}
        label={label}
        disabled={readOnly}
        {...rest}
        error={meta.touched ? !!(meta.error) : false}
      />
      {meta.touched  && !!(meta.error) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </FlexRow>
  );
};

export { powerValid };
export default TextField;
