import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../actions/altHome';
import { i18n } from "../../config";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Grid, Paper, Button, Typography, Box } from '@material-ui/core';
import { dispatchNewRoute} from '../../utils/http_functions';
import Settings, { features } from "../../settings";
import {contraction_url} from '../../constants/i18n';
import ContractsIcon from "../../overrides/pages/AltHomeView/ContractsIcon";
import InvestmentsIcon from "../../overrides/pages/AltHomeView/InvestmentsIcon";
import InvoicesIcon from "../../overrides/pages/AltHomeView/InvoicesIcon";
import _ from "lodash";

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

const style = {
    main: {
        marginTop: 50,
    },
    gridItem: {
        width: "350px",
    },
    root: {
        width: '100%',
    },
    verticalList: {
        width: "100%",
        display: "inline-block",
    },
    button: {
        textAlign: 'left',
    },
};

class AltHomeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        };
    }

    contractFun(funktion) {
        this.props.altContractFunction(funktion);
        dispatchNewRoute(i18n.t('common:url.alt_contracts_detail'))
    }

    invoiceFun(funktion) {
        //Not used, but may be useful in a future, works well.
        this.props.altInvoiceFunction(funktion);
        dispatchNewRoute(i18n.t('common:url.invoices'))
    }

    render() {
        return (
            <div>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Grid item style={style.gridItem}>
                        <Paper style={style.main}>
                        {
                            <div style={style.root}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary classes={{content: 'custom-summary-with-logo'}}>
                                        <ContractsIcon/>
                                        <Box mt={3} mb={2}>
                                            <Typography variant="h5" noWrap>
                                                {i18n.t('common:text.altHome_contract_title')}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails style={style.verticalList}>
                                        <div>
                                            <Button 
                                                color="primary"
                                                onClick={() => dispatchNewRoute(contraction_url)}
                                                style={style.button}
                                            >
                                                {i18n.t('common:text.altHome_contract_create')}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                color="primary"
                                                onClick={() => dispatchNewRoute(i18n.t('common:url.contracts'))}
                                                style={style.button}
                                            >
                                                {i18n.t('common:text.altHome_contract_view')}
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                color="primary"
                                                onClick={() => this.contractFun('download')}
                                                style={style.button}
                                            >
                                                {i18n.t('common:text.altHome_contract_download')}
                                            </Button>
                                        </div>
                                        { features.powerChange &&
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.contractFun('powChange')}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_contract_power')}
                                                </Button>
                                            </div>
                                        }
                                        { features.ibanChange &&
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.contractFun('ibanChange')}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_contract_iban')}
                                                </Button>
                                            </div>
                                        }
                                        { features.notificacionAddressChange && 
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.contractFun('notifAddrChange')}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_contract_contact')}
                                                </Button>
                                            </div>
                                        }
                                        { features.noCesionSips &&
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.contractFun('noCesionSips')}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_contract_sips')}
                                                </Button>
                                            </div>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        }
                        </Paper>
                    </Grid>
                    <Grid item style={style.gridItem}>
                        <Paper style={style.main}>
                        {
                                <div style={style.root}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary classes={{content: 'custom-summary-with-logo'}}>
                                            <InvoicesIcon/>
                                            <Box mt={3} mb={2}>
                                                <Typography variant="h5" noWrap>
                                                    {i18n.t('common:text.altHome_invoices_title')}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails style={style.verticalList}>
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => dispatchNewRoute(i18n.t('common:url.invoices'))}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_invoices_view')}
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => dispatchNewRoute(i18n.t('common:url.invoices'))}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_invoices_download')}
                                                </Button>
                                            </div>
                                            {_.get(Settings, 'invoices.enablePayment', false) &&
                                              <div>
                                                <Button
                                                  color="primary"
                                                  onClick={() => dispatchNewRoute(i18n.t('common:url.invoices'))}
                                                  style={style.button}
                                                >
                                                  {i18n.t('common:text.altHome_invoices_payonline')}
                                                </Button>
                                              </div>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                        </Paper>
                    </Grid>
                    <Grid item style={style.gridItem}>
                        <Paper style={style.main}>
                            {
                                <div style={style.root}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary classes={{content: 'custom-summary-with-logo'}}>
                                            <InvestmentsIcon/>
                                            <Box mt={3} mb={2}>
                                                <Typography variant="h5" noWrap>
                                                    {i18n.t('common:text.altHome_consumption_title')}
                                                </Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails style={style.verticalList}>
                                            <div>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.contractFun('consumption')}
                                                    style={style.button}
                                                >
                                                    {i18n.t('common:text.altHome_consumption_view')}
                                                </Button>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltHomeView);

AltHomeView.propTypes = {

};
