import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import useTypes from "./useTypes";

const NumericFilter = ({defaultInputType, resetValue, categoryName, categoryUnits, handleFilterValueChange, handleCreate}) => {
  const { numericTypes: types } = useTypes();
  const [inputType, setInputType] = useState(types[defaultInputType]); 
  const [innerValue, setInnerValue] = useState(types[defaultInputType].defaultValue);
  
  const getNumericField = () => {
    switch (inputType?.input) {
      case "text": 
        return <Grid item xs={12} md="auto" style={{flexGrow: 1}}>
          <TextField 
            label={inputType.title} 
            size="small"
            variant="outlined"
            InputProps={{endAdornment: <InputAdornment position="end">{categoryUnits}</InputAdornment>}}
            onChange={(event) => handleValueChange(event.target.value.replace(/[^0-9\,\.\-]/g, ""))}
            fullWidth
            value={innerValue}
            onKeyUp={(e) => {e.key === "Enter" && handleCreate()}}
          />
        </Grid>

      case "range": 
        return <Grid item container xs justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs>
            <TextField 
              size="small"
              variant="outlined"
              type="number"
              InputProps={{endAdornment: <InputAdornment position="end">{categoryUnits}</InputAdornment>}}
              onChange={(event) => {
                handleValueChange([event.target.value.replace(/[^0-9\,\.\-]/g, ""), innerValue[1]])}
              }
              fullWidth
              value={innerValue[0]}
            />
          </Grid>
          <Grid item>
            -
          </Grid>
          <Grid item xs>
            <TextField 
              size="small"
              variant="outlined"
              type="number"
              InputProps={{endAdornment: <InputAdornment position="end">{categoryUnits}</InputAdornment>}}
              onChange={(event) => handleValueChange([innerValue[0], event.target.value.replace(/[^0-9\,\.\-]/g, "")])}
              fullWidth
              value={innerValue[1]}
            />
          </Grid>

        </Grid>
      
      default:
        <></>
    }
  };

  const handleValueChange = (newValue = innerValue, type = inputType) => {
    setInnerValue(newValue);
    handleFilterValueChange(newValue, type.render);
  }

  const handleInputTypeChange = (newInputType) => {
    if (typeof newInputType.defaultValue !== typeof inputType.defaultValue) {
      handleValueChange(newInputType.defaultValue, newInputType);
    } else {
      handleValueChange(innerValue, newInputType);
    }
    setInputType(newInputType);
  }

  useEffect(() => {
    handleValueChange(inputType.defaultValue);
  }, [categoryName, resetValue]);

  return (
    <Grid item container xs spacing={1}>
      <Grid item xs={12} md={4}>
        <Autocomplete
          id="combo-box-demo"
          options={Object.values(types)}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, selected) => option.title === selected.title}
          renderInput={(params) => <TextField {...params} label="Tipus" variant="outlined" size="small" />}
          onChange={(event, newInputType) => handleInputTypeChange(newInputType)}
          disableClearable={inputType !== null}
          value={inputType}
        />
      </Grid>
      {getNumericField()}
    </Grid>
  );
}

export default NumericFilter;