import { createReducer } from '../utils/misc';

import {
  REGISTER_COUPON,
} from '../constants/index';

const initialState = {
  coupons: null,
  data: null
};

export default createReducer(initialState, {
  ['FETCH_COUPONS_RECEIVE']: (state, payload) => {
    return {
      ...state, 
      data: payload.coupons,
    }
  },
  [REGISTER_COUPON]: (state, payload) => {
    return {
      ...state,
      coupons: payload.coupons,
    }
  }
});
