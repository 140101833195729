import React from 'react';
import { SvgIcon } from '@material-ui/core';

const LightIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50">
           <path className="cls-1" d="M27.79,37.5a1.25,1.25,0,0,1-1.25-1.25V23H23.61V36.25a1.25,1.25,0,0,1-2.5,0V23h-2.7a1.58,1.58,0,0,1-1.57-1.83c.18-1.1,1.06-4.73,5.07-5a1.58,1.58,0,0,1,1.7,1.58v2.75h2.93V17.78A1.6,1.6,0,0,1,28.3,16.2a5.23,5.23,0,0,1,4.87,5.16,1.54,1.54,0,0,1-.43,1.17,1.61,1.61,0,0,1-1.15.49H29V36.25A1.24,1.24,0,0,1,27.79,37.5ZM19.31,21.58ZM29,20.52h1.47A2.47,2.47,0,0,0,29,18.94Zm-9.44,0h1.51V18.9A2.84,2.84,0,0,0,19.6,20.52Z"/>
           <path className="cls-1" d="M32.25,38.21H17.74a2.24,2.24,0,0,1-2.24-2.09c-.22-2.62-1.38-5.39-3.66-8.73A15.87,15.87,0,0,1,25.43,2.64,15.69,15.69,0,0,1,36.64,7.76,15.83,15.83,0,0,1,38.16,27.4c-1.44,2.1-3.38,5.29-3.66,8.72A2.25,2.25,0,0,1,32.25,38.21ZM18,35.71H32c.38-3.94,2.49-7.43,4.06-9.72a13.38,13.38,0,1,0-22.18,0C15.47,28.27,17.59,31.76,18,35.71Z"/>
           <path className="cls-1" d="M31.1,43H18.9a2.53,2.53,0,0,1-2.25-2.77V36.94a1.25,1.25,0,0,1,2.5,0v3.27a1,1,0,0,0,0,.27H30.81a1,1,0,0,0,0-.27V36.94a1.25,1.25,0,1,1,2.5,0v3.27A2.52,2.52,0,0,1,31.1,43Z"/>
           <path className="cls-1" d="M25,47.37a4.8,4.8,0,0,1-4.79-4.79,1.25,1.25,0,0,1,2.5,0,2.29,2.29,0,0,0,4.58,0,1.25,1.25,0,0,1,2.5,0A4.8,4.8,0,0,1,25,47.37Z"/>
        </SvgIcon>   
    );
}

export default LightIcon;