import React from "react";
import LegacyCUPSField, { cupsValid } from "../CUPSField";

const CUPSField = ({ input, meta, label, ...rest }) => (
  <LegacyCUPSField
    {...input}
    floatingLabelText={label}
    {...rest}
    error={meta.touched ? meta.error : ""}
  />
);

export default CUPSField;
export { cupsValid };
