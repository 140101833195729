import { useState } from "react";
import { useEffect } from "react";
import { dispatchNewRoute } from '../../utils/http_functions';
import Settings from "../../settings";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import useServerElements from "./useServerElements";


/**
 * Fetches all available navigation routes for the current user.
 * Each route navigates to a path relative to the specified base path (default is '/dynamic') 
 * appended with the received button path, for example: '/dynamic/<received-button-path>'.
 * 
 * Props:
 *   - WrapperComponent (default: 'div'): Component used to wrap the rendered navigation elements.
 *   - ClickableComponent (default: 'Button'): Component used to render each clickable navigation button.
 *   - Props for WrapperComponent and ClickableComponent: Additional props to be passed to the WrapperComponent and ClickableComponent.
 *
**/
const DinamicServerNavigation = ({ 
  pathRelativeTo = "/dinamic",
  wrapperComponent:WrapperComponent,
  wrapperProps,
  clickableComponent: ClickableComponent,
  clickableProps,
}) => {
  const { fetchServerActions } = useServerElements();
  const [links, setLink] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.auth);

  if (!WrapperComponent)
    WrapperComponent = ({children, ...rest}) => <div {...rest}>{children}</div>;
  if (!wrapperProps)
    wrapperProps = {};
  if (!ClickableComponent)
    ClickableComponent = ({children, ...rest}) => <Button variant="contained" {...rest}>{children}</Button>;
  if (!clickableProps)
    clickableProps = {};

  useEffect(async () => {
    if (isAuthenticated) {
      setLink(await fetchServerActions());
    } else {
      setLink([]);
    }
  }, [isAuthenticated]);


  const drawedMenuItems = links.map((l) => {
    return (
      <WrapperComponent 
        {...wrapperProps} 
        key={l.id}>
        <ClickableComponent 
          {...clickableProps}
          onClick={() => dispatchNewRoute(`${pathRelativeTo}${l.route_path}/${l.id}`)} // pathRelativeTo + desiredRoute + pageID
          title={l.btn_description}
        >

          {l.btn_title}
        </ClickableComponent>
      </WrapperComponent>
    )
  })


  if (Settings.features?.enableServerElements)
    return drawedMenuItems;
  return null;
}

export default DinamicServerNavigation;
