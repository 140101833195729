import { Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    backgroundColor: "white",
    border: "1px solid grey",
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    borderRadius: 4,
  },
  title: {
    margin: "15px 0 15px 0"
  },
  content: {
    componentStyle: {
      marginTop: "10px"
    },
    containerStyle: {
      display: "flex"
    },
    squareStyle: {
      width: "20px",
      height: "20px",
      borderRadius: "4px"
    },
    textStyle: {
      marginLeft: "5px",
      color: "black"
    }
  }
};

export const Tooltip = ({ payload, label, groupTitle, showTooltipInfo }) => {
  const { i18n, t } = useTranslation();

  return (
    <div style={styles.container} className="custom-tooltip">
      <h4 style={styles.title}>
        <strong>{groupTitle} {label}</strong>
      </h4>
      {
        payload.map(({name, color, value, unit}, i) => {
          
          if (name === "total") {
            return (
              <p key={"tooltip" + i} style={styles.content.componentStyle}>
                <strong>{name.toUpperCase()}</strong>: {parseFloat(value).toLocaleString(i18n.language)} {unit}
              </p>
            )
          }

          return (
            <div key={"tooltip" + i} style={styles.content.containerStyle}>
              <div style={{...styles.content.squareStyle, backgroundColor: color}}></div>
              <div style={styles.content.textStyle}>
                <strong>{name}</strong>: {value.toLocaleString(i18n.language)} {unit}
              </div>
            </div>
          )
        })
      }

      {showTooltipInfo && 
        <Typography variant='caption'>{t("common:text.contract_cch_hourly_clarification")}</Typography>
      }
      
    </div>

  );
}
