import { useState } from "react";

import { Card, CardActionArea, Button, Tooltip, Grid } from "@material-ui/core";

import { FileCopyOutlined, DoneOutlined } from '@material-ui/icons';

import { Share } from "./Share";
import { useTranslation } from "react-i18next";

export const CopyElement = ({
  labelText,
  value,
}) => {
  const { t } = useTranslation();
  const [innerCopied, setInnerCopied] = useState(false);
  const [hoveredItem, setHoveredItem] = useState('');

  const elementCopied = () => {
    setInnerCopied(true);
    navigator.clipboard.writeText(value).then(x => setTimeout(() => { setInnerCopied(false) }, 2500));
  }

  return <>  
    <Tooltip
      title={hoveredItem === 'copy' ? (innerCopied ? t('common:text.coupon_copied') : t('common:text.coupon_copy')) : t('common:text.coupon_share')}
      arrow placement="right"
    >
      <Card>
        <Grid container>
          <Grid item xs>
            <CardActionArea
              style={{ padding: "5px", paddingLeft: "15px", height: "100%" }}
              onClick={elementCopied}
              onMouseEnter={() => setHoveredItem('copy')}
            >
              {labelText}
            </CardActionArea>
          </Grid>
          
          <Grid item container xs={12} sm={3} justifyContent="space-around" alignItems="center">
            <Grid item>
              <Button color="primary" onClick={elementCopied} onMouseEnter={() => setHoveredItem('copy')} startIcon={innerCopied ? <DoneOutlined /> : <FileCopyOutlined />} />
            </Grid>

            <Grid item>
              <Share color="primary" url={value} onMouseEnter={() => setHoveredItem('share')} />
            </Grid>
          </Grid>
          
        </Grid>
      </Card>
    </Tooltip>
  </>
}