import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/auth';

import { debug } from '../utils/debug';
import { i18n } from "../config";
import { Box, Typography } from '@material-ui/core';

const styles = {
    center: {
        textAlign: 'center',
    },
};

function mapStateToProps(state) {
    return {
        data: state.data,
        token: state.auth.token,
        userName: state.auth.userName,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

class NotFound extends React.Component { // eslint-disable-line react/prefer-stateless-function
    render() {
        return (
            <div style={styles.center}>
                <Box>
                    <Typography variant="h4">
                        {i18n.t('common:text.notfound_view_title')} 
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h6">
                        {i18n.t('common:text.notfound_view_subtitle')}
                    </Typography>
                </Box>
                <p><br/><img src="/images/404.png" alt=""/><br/><br/></p>
                <p>{i18n.t('common:text.notfound_view_message')} <a href={i18n.t('common:url.contact')}>{i18n.t('common:text.notfound_view_message_link')}</a>.</p>

                {debug(this.props)}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
