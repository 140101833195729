import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newGasContract";
import _ from "lodash";

import { Form, Field } from "react-final-form";

import CheckboxField from "../form-fields/CheckboxField";
import IBANField, { IBANvalid } from "../form-fields/IBANField";

import { CircularProgress, Button, Box, Typography } from "@material-ui/core"
import { i18n } from "../../config";

import Settings from "../../settings";

function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    ...state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const FormPayment = (props) => {

  const handleGoBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  const handleSubmit = async (values) => {
    props.submitPaymentData(values);

    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <div>
      { _.get(Settings, "newGasContract.sectionHeaders", false) &&
        <Box>
          <Typography variant="h4">{i18n.t('common:text.contractation_about_payment')}</Typography>
        </Box>
      }
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          iban: props.iban,
          isOwner: props.isOwner,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.iban) {
            errors.iban = i18n.t('common:text.required_field');
          } else if (!IBANvalid(values.iban)) {
            errors.iban = i18n.t('common:text.iban_field_invalid');
          }

          if (!values.isOwner) {
            errors.isOwner = i18n.t('common:text.required_field');
          }

          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="iban" component={IBANField} style={{width: 300}}/>
            <div>
              <Field
                name="isOwner"
                label={i18n.t('common:text.contractation_owner_confirmation')}
                component={CheckboxField}
              />
            </div>

            <Button
              variant={'text'}
              onClick={handleGoBack}
              style={{ marginRight: 12 }}
              disabled={submitting}
            >
              {i18n.t('common:text.contractation_previous')}
            </Button>
            <Button
              type={"submit"}
              color={'primary'}
              variant={'contained'}
              disabled={submitting}
            >
              {submitting ? <CircularProgress size={25} /> : null}
              {i18n.t('common:text.contractation_next')}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPayment);
