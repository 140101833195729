import React from "react";

const FlexRow = (props) => {
  return (
    <div style={{ ...props.style, ...{ display: "flex" } }}>
      {props.children}
    </div>
  );
};

export default FlexRow;