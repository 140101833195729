import aireacondImage from "./aire.png";
import calefaccioImage from "./radiador.png";
import calefaccioGeneralImage from "./calefaccio.png";
import cotxeImage from "./cotxe.png";
import fornImage from "./forn.png";
import microonesImage from "./microones.png";
import neveraImage from "./nevera.png";
import rentadoraImage from "./rentadora.png";
import rentaplatsImage from "./rentaplats.png";
import secadoraImage from "./secadora.png";
import termoImage from "./termo.png";
import tvImage from "./tv.png";
import vitroImage from "./vitro.png";
import piscinaImage from "./piscina.png";
import gasoilImage from "./gasoildomicili.png";
import { i18n } from "../../../config";

export const APPLIANCES = [
  {
    name: i18n.t('common:text.appliance_estufa'),
    img: calefaccioGeneralImage,
    multiple: true,
    cofSimulDependent: true,
    options: [{
     power: 1.2
    }]
  },
  {
    name: i18n.t('common:text.appliance_calefaccio'),
    img: calefaccioImage,
    multiple: false,
    areaDependent: true,
    cofSimulDependent: true,
    base: 0.1,
    options: [
      {power: 0.01}
    ]
  },
  {
    name: i18n.t('common:text.appliance_termo'),
    img: termoImage,
    multiple: false,
    optionsLabel: i18n.t('common:text.appliance_termo_capacitat'),
    cofSimulDependent: true,
    options: [
      {
        name: "30L",
        power: 1.2
      },
      {
        name: "50L",
        power: 1.6
      },
      {
        name: "80L",
        power: 2
      },
      {
        name: "100L",
        power: 2.4
      }
    ]
  },
  {
    name: i18n.t('common:text.appliance_rentaplats'),
    img: rentaplatsImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 1.7
    }]
  },
  {
    name: i18n.t('common:text.appliance_rentadora'),
    img: rentadoraImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 2
    }]
  },
  {
    name: i18n.t('common:text.appliance_secadora'),
    img: secadoraImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 2
    }]
  },
  {
    name: i18n.t('common:text.appliance_forn'),
    img: fornImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 2
    }]
  },
  {
    name: i18n.t('common:text.appliance_cuina'),
    img: vitroImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 1.5
    }]
  },
  {
    name: i18n.t('common:text.appliance_aire'),
    img: aireacondImage,
    multiple: true,
    cofSimulDependent: true,
    options: [{
      power: 1.5
    }]
  },
  {
    name: i18n.t('common:text.appliance_micro'),
    img: microonesImage,
    multiple: false,
    cofSimulDependent: true,
    options: [{
      power: 1.1
    }]
  },
  {
    name: i18n.t('common:text.appliance_frigo'),
    img: neveraImage,
    cofSimulDependent: false,
    multiple: true,
    options: [{
      power: 0.3
    }]
  },
  {
    name: i18n.t('common:text.appliance_tv'),
    img: tvImage,
    multiple: true,
    options: [{
      power: 0.2
    }]
  },
  {
    name: i18n.t('common:text.appliance_ve'),
    img: cotxeImage,
    cofSimulDependent: false,
    multiple: false,
    options: [{
      power: 0
    }],
    hint: i18n.t('common:text.appliance_ve_hint')
  },
  {
    name: i18n.t('common:text.appliance_piscina'),
    img: piscinaImage,
    multiple: true,
    cofSimulDependent: true,
    multipleLabel: i18n.t('common:text.appliance_piscina_qty'),
    options: [{
      power: 0.0085
    }]
  },
  {
    name: i18n.t('common:text.appliance_gasoil'),
    img: gasoilImage,
    multiple: false,
    cofSimulDependent: false,
    options: [{
      power: 0
    }]
  }
];

export const ownIcons = true;
