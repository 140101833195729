import React from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import { isValid, printFormat } from 'iban';
import validate from 'validate.js';
import { i18n } from "../config";

export const IBANvalid = (iban) => {
  return isValid(iban);
};

class IBANField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      errorText: props.errorText
    }

    validate.validators.isValid = (value, options) => {
      if (!value && options.allowEmpty) {
        return null;
      } else {
        if (!IBANvalid(value)) {
          return i18n.t('common:text.iban_field_invalid');
        } else {
          return null;
        }
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.errorText !== props.errorText) {
      return {
        errorText: props.errorText || ''
      };
    };

    return null;
  }

  handleOnChange = (event) => {
    const value = printFormat(event.target.value);
    const isIBANValid = isValid(value);
    event.target.value = value;
    event.target.isValid = isIBANValid;
    const errorText = isIBANValid ? '' : i18n.t('common:text.iban_field_invalid');
    this.setState({value, errorText});
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  render() {
    return (
      <>
        <TextField
          label={i18n.t('common:text.iban_field_label')}
          placeholder={i18n.t('common:text.iban_field_placeholder')}
          onChange={this.handleOnChange}
          value={this.state.value}
          error={this.state.errorText !== ''}
          style={this.props.style}
        />
        { (this.state.errorText !== '') &&
          <FormHelperText error={true} id="iban-error-text">
            {this.state.errorText}
          </FormHelperText>
        }
      </>
    );
  }

}

export default IBANField;


IBANField.defaultProps = {
  errorText: '',
  value: '',
  style: {},
};
