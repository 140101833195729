import _ from "lodash";
import React from 'react';
import { connect } from 'react-redux';
import { fetchAddress, setAddress } from "../../actions/contracts";
import { fetchGasAddress, setGasAddress } from "../../actions/contractsGas";
import Address, { validarReferenciaCatastral } from "../Address";
import validate from "validate.js";
import {LoadingAnimation} from "../LoadingAnimation";
import { i18n } from "../../config";
import {
  Button, CircularProgress, FormControl,
  InputLabel, Select, MenuItem,
  RadioGroup, FormControlLabel, Radio, Grid, Divider
} from '@material-ui/core'


const mapStateToProps = (state) => {
  return {
    availableAddressesElectricity: state.contracts.availableAddresses || [],
    availableAddressesGas: state.contractsGas.availableAddresses || [],
    setting_address: state.contracts.sending,
    defaultName: state.contractsGas.view_contract ? 
        state.contractsGas.view_contract.notification_address.name : 
        state.contracts.view_contract.notification_address.name,
  }
};


class ChangeAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressType: 'existing',
      selected: props.actual.id,
      address: {name: this.props.defaultName},
      type: props.type?props.type:'electricty',
      errors: {}
    };
    
    validate.validators.validarReferenciaCatastral = (value, options) => {
      if (!value && options.allowEmpty) {
        return null;
      } else {
        const result = validarReferenciaCatastral(value);
        if (result !== 1) {
          return i18n.t('common:text.contractation_not_valid_ref');
        } else {
          return null;
        }
      }
    };
  }

  handleOnChangeAddress = (value) => {
    this.setState({address: value});
  };

  handleOnChangeAddressType = (event, index, value) => {
    this.setState({addressType: event.target.value});
  };

  handleSetNotificationAddress = (type) => {
    if (type === "gas") {
      this.props.setGasAddress({
        address_type: this.state.addressType,
        address_id: this.state.selected
      });
    } else {
      this.props.setAddress({
        address_type: this.state.addressType,
        address_id: this.state.selected
      });
    }
  };

  handleCreateNotificationAddress = () => {
    const VALIDATIONS = {
      zipcode: {
        presence: {allowEmpty: false},
        length: {
          is: 5,
          message: i18n.t('common:text.change_address_validation'),
        }
      },
      name: {
        presence: {allowEmpty: false}
      },
      city: {
        presence: {allowEmpty: false},
      },
      tv: {
        presence: {allowEmpty: false},
      },
      nv: {
        presence: {allowEmpty: false},
      },
      pnp: {
        presence: {allowEmpty: false},
      },
      ref_catastral: {
        validarReferenciaCatastral: {allowEmpty: true}
      },
      email: {
        presence: {allowEmpty: false},
        email: true,
      },
      mobile: {
        presence: {allowEmpty: false}
      }
    };
    const errors = validate(this.state.address, VALIDATIONS);
    if (errors) {
      this.setState({errors})
    } else {
      const address = Object.assign({}, this.state.address);
      address['state_id'] = address.state.id;
      address['tv'] = address.tv.id;
      address['municipi'] = address.city.ine;
      address['city'] = address.city.city;
      if (this.props.type === "gas") {
        this.props.setGasAddress({
          address_type: this.state.addressType,
          address
        }, this.props.contractId);
      } else {
        this.props.setAddress({
          address_type: this.state.addressType,
          address
        });
      }
    }
  };

  handleOnChangeExistingAddress = (event, value) => {
    this.setState({selected: Number(event.target.value)});
  };

  componentDidMount() {
    this.setState({ fetching: true });
    if (this.props.type === 'gas') {
      this.props.fetchGasAddress(this.props.contractId);
    } else {
      this.props.fetchAddress();
    }
    if (_.isEmpty(this.props.availableAddresses)) {
      this.setState({ addressType: 'new' });
    }
    this.setState({ fetching: false });
  }

  render() {

    const setting = this.props.setting_address;
    const { availableAddressesElectricity, availableAddressesGas } = this.props;

    let availableAddresses = [];
    if(this.props.type === "gas") {
      availableAddresses = availableAddressesGas;
    } else {
      availableAddresses = availableAddressesElectricity;
    }
    
    const addressesChoices =
        !_.isEmpty(availableAddresses) ?
        availableAddresses.map((addr) => {
          Object.keys(addr).forEach(function(key) {
            if(addr[key] === 'False') {
              addr[key] = ' '
            }
          });
          const label = (
            <div>
              <div>{addr.name}</div>
              <div>{addr.street} - {addr.zipcode} {addr.city}</div>
              <div>{addr.email}</div>
              <div>{addr.mobile}</div>
            </div>
          );
          return <FormControlLabel key={addr.id} value={addr.id} control={<Radio />} label={label} disabled={setting}/>
        }) : [];

    return (
      <div>
        <h2>{ i18n.t('common:text.change_address_edit') }</h2>
        <Grid container spacing={3}> 
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth >
              <InputLabel shrink htmlFor="">
                {i18n.t("common:text.change_address_type")}
              </InputLabel>
              <Select
                onChange={this.handleOnChangeAddressType}
                value={this.state.addressType}
              >
                <MenuItem key="existing" value="existing">{i18n.t('common:text.change_address_existing')}</MenuItem>
                <MenuItem key="new" value="new">{i18n.t('common:text.change_address_new')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider style={{marginTop: 20}}/>
        { this.state.addressType === 'new' &&
          <div>
            <Address value={this.state.address} onChange={this.handleOnChangeAddress} showEmail showMobile showName errors={this.state.errors}/>
            <div style={{marginTop: '20px'}}>
              <Button
                color={'primary'}
                onClick={this.handleCreateNotificationAddress}
                disabled={setting}
              >
                {i18n.t('common:text.change_address_creation')}
              </Button>
            </div>
          </div>

        }
        { this.state.addressType === 'existing' &&
          <div>
            {!_.isEmpty(addressesChoices) ?
            <div>
              <RadioGroup
                defaultValue={this.props.actual.id}
                onChange={this.handleOnChangeExistingAddress}
                name="selectAddress"
                disabled={this.props.setting_address}
                value={this.state.selected}
              >
                {addressesChoices}
              </RadioGroup>
              <div style={ {marginTop: '20px'} }>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={this.handleSetNotificationAddress}
                  disabled={setting}
                >
                  {setting ? <CircularProgress size={25} /> : null}
                  {i18n.t('common:text.change_address_update')}
                </Button>
              </div>
            </div>
            :
            (!this.state.fetching &&
            <div>
              {i18n.t('common:text.change_address_no_data')}
            </div>
            )}
            {this.state.fetching &&
            <div>
              <LoadingAnimation />
            </div>
            }
          </div>
        }
      </div>
    );
  }

}

export default connect(mapStateToProps, { fetchAddress, setAddress, fetchGasAddress, setGasAddress })(ChangeAddress);
