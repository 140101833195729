import React, { useState } from "react";
import validate from 'validate.js';
import {data_create_api_resource} from "../../utils/http_functions";
import { Link } from 'react-router-dom';
import { i18n } from "../../config";

import { CircularProgress, Button, TextField, FormHelperText } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const VALIDATIONS = {
  nif: {
    presence: {allowEmpty: false}
  },
  email: {
    presence: {allowEmpty: false},
    email: true
  }
};


const UserResetPassword = (props) => {

  const [nif, setNif] = useState("");
  const [email, setEmail] = useState("");
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    const errors = validate({email: email, nif: nif}, VALIDATIONS);
    setErrors(errors || {});

    if (!errors){
      try {
        const result = await data_create_api_resource(null, 'user/reset/', {email: email, nif: nif});
        setSubmitting(false);
        setSubmitSucceeded(true);
      } catch (e) {
        if (e.response && e.response.status === 400) {
          setErrors(e.response.data.errors);
        }
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <h1 style={{textAlign: 'middle'}}>{i18n.t('common:text.password_recovery')}</h1>
      {submitSucceeded &&
        <div>
          <p>
          {i18n.t('common:text.password_restored_description')}
          </p>
          <Link to={i18n.t('common:url.login')}>{i18n.t('common:text.login_view_title')}</Link>
        </div>
      }
      { !submitSucceeded &&
      <div>
        <div style={{width: 200}}>
          <TextField
            name="nif"
            label={"NIF"}
            error={!!(errors.nif)}
            disabled={submitting}
            style={{width: 200}}
            onChange={(e) => setNif(e.target.value)}
          />
          {!!(errors.nif) &&
            <FormHelperText error={true}>
              {errors.nif.join(", ")}
            </FormHelperText>
          }
        </div>
        <div style={{width: 200}}>
          <TextField
            name="email"
            label={"Correo electrónico"}
            error={!!(errors.email)}
            disabled={submitting}
            style={{width: 200}}
            onChange={(e) => setEmail(e.target.value)}
          />
          {!!(errors.email) &&
            <FormHelperText error={true}>
              {errors.email.join(", ")}
            </FormHelperText>
          }
        </div>
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={submitting}
          style={{ marginTop: 20 }}
          onClick={handleSubmit}
        >
          {submitting ? <CircularProgress size={25} /> : <Send />}
          {"Enviar"}
        </Button>
      </div>
      }
    </div>
  );
}

export default UserResetPassword;
