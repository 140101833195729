import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/auth";

import { debug } from "../utils/debug";
import { i18n, dayjs } from "../config";
import { locale_code } from "../constants/i18n";
import Settings from "../settings";
import _ from "lodash";
import { Box, Typography } from "@material-ui/core";

const styles = {
  center: {
    textAlign: "center",
  },
};

function mapStateToProps(state) {
  return {
    data: state.data,
    token: state.auth.token,
    userName: state.auth.userName,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class MaintenanceView extends React.Component {

  outOfOrderMessage() {
    dayjs.locale(locale_code);
    let starts = _.get(Settings, "maintenance.starts");
    let startTime = dayjs(starts).format("LT");
    let startDate = dayjs(starts).format("L");
    
    let ends = _.get(Settings, "maintenance.ends");
    let endTime = dayjs(ends).format("LT");
    let endDate = dayjs(ends).format("L");
    
    let timeTemplate = () => "";
    if (starts && ends) {
      timeTemplate = _.template(i18n.t('common:text.maintenance_view_message_out_of_order_start_end'));
    } else if (ends) {
      timeTemplate = _.template(i18n.t('common:text.maintenance_view_message_out_of_order_end'));
    }
    return timeTemplate({ startTime, startDate, endTime, endDate });
  }

  render() {
    return (
      <div style={styles.center}>
        <Box>
          <Typography variant="h4">
            {i18n.t('common:text.maintenance_view_title')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            {i18n.t('common:text.maintenance_view_subtitle')}
          </Typography>
        </Box>
        <p>
          <br />
          <img src="/images/logo.png" alt=""/>
          <br />
          <br />
        </p>
        <p>
          {i18n.t('common:text.maintenance_view_message')}{" "}{this.outOfOrderMessage()}
        </p>

        {debug(this.props)}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView);
