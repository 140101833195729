import _ from "lodash";
import React from "react";
import FlexRow from "../FlexRow";
import Phone from "phone";

import { TextField, FormHelperText } from "@material-ui/core"

const PhoneField = ({ input, label, readOnly = false, meta, ...rest }) => {
  return (
    <FlexRow style={{ flexWrap: "wrap", flexDirection: "column" }}>
      <TextField
        label={label}
        disabled={readOnly}
        {...input}
        {...rest}
        error={meta.touched && !!(meta.error)}
      />
      {!!(meta.touched) &&
        <FormHelperText error={true}>
          {meta.error}
        </FormHelperText>
      }
    </FlexRow>
  );
};

export default PhoneField;

export const validPhone = (value) => {
  const spanishFormattedValue = Phone(value, "ESP", true);
  const foreignFormattedValue = Phone(value, "", true);
  if (_.isEmpty(spanishFormattedValue) && _.isEmpty(foreignFormattedValue)) {
    return false;
  } else {
    return true;
  }
};
