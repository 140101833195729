import {
  data_fetch_api_resource,
  check_url_exists
} from '../utils/http_functions'

import {
  parseJSON
} from '../utils/misc'

import {
  REGISTER_COUPON
} from "../constants";

// ----------------------------------------------------------------------------
// Coupons
// ----------------------------------------------------------------------------

export const registerCoupon = (payload) => {
  if ('promocodes' in payload['qs']) {

    return {
      type: REGISTER_COUPON,
      payload: {
        coupons: payload['qs']['promocodes']
      },
    };
  }
};

export function fetchCouponsRequest() {
  return {
    type: 'FETCH_COUPONS_REQUEST',
  }
}

export function fetchCouponsReceive(response) {
  return {
    type: 'FETCH_COUPONS_RECEIVE',
    payload: {
      coupons: response
    }
  }
}

export function fetchCoupons(token) {
  return (dispatch) => {
    let url = `user/coupons`;
    dispatch(fetchCouponsRequest());
    data_fetch_api_resource(token, url)
    .then(parseJSON)
    .then(response => {
      dispatch(fetchCouponsReceive(response));
    })
    .catch(err => {});
  };
}
