import _ from "lodash";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actionCreators from "../../actions/newGasContract";
import { i18n } from "../../config";
import { LoadingAnimation } from '../LoadingAnimation';
import RichContent from "../RichContent";
import Settings from "../../settings";

import { red } from '@material-ui/core/colors';
import {Radio, RadioGroup, FormControlLabel, Grid, Typography, Card, CardActionArea, CardContent, Checkbox} from "@material-ui/core"
import { withStyles, lighten, alpha } from '@material-ui/core/styles';

const styles = theme => ({
  formControlLabel: {
    display: "inherit",
    height: "100%",
    top: 0,
    width: "100%",
    position: "absolute",
    '& .MuiRadio-root': {
      display: "none"
    }
  },
  rootCard: {
    '&.selected': {
      border: "1px solid " + theme.palette.secondary.main + " !important",
      boxShadow: "0px 0px 5px 0px " + alpha(theme.palette.secondary.main ,0.75),
    },
    '&:hover': {
      border: "1px solid " + lighten(theme.palette.secondary.main, 0.5)
    },
    height: "100%",
    '& .MuiCardActionArea-root': {
      height: "100%",
      '& .MuiCardContent-root': {
        paddingTop: 5,
        height: "100%",
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-root': {
            color: theme.palette.primary.main,
            fontWeight: 600
          }
        }
      }
    },
    '& .MuiCardActionArea-focusHighlight': {
      // backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTouchRipple-root': {
      display: "none",
    }
  },
});

function mapStateToProps(state) {
  return {
    ...state.newGasContract
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

class GasProductSelect extends React.Component {

  fetchAvailableProducts = async () => {
    if (this.props.cups && this.props.accessTariff.id) {
      this.props.fetchAvailableGasProducts(this.props.cups, this.props.accessTariff.id);
    }
  };

  componentDidMount() {
    this.fetchAvailableProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.cups !== prevProps.cups){
      this.fetchAvailableProducts();
    }
  }

  handleChange = (event, value) => {
    const selected = this.props.availableProducts.filter((t) => t.id === Number(value));
    const product = selected[0];
    this.props.selectGasProduct(product);
    if (typeof this.props.onChange === "function") {
      this.props.onChange(null, product);
    }
  };

  renderProduct = (t) => {
    // This component in very hacky. It's a radio group that uses the radio component stretched to fill the card
    // component via JSS styles, this way it can be clicked; and uses another checkbox to show if it's checked.
    // If I ever revisit this component, this could be a good starting point:
    // https://v4.mui.com/components/text-fields/#customized-inputs
    return (
      <Card variant="outlined" className={`${this.props.classes.rootCard} ${_.get(this.props, "selectedProduct.id", -1) === t.id ? "selected" : ""}`}>
        <CardActionArea>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={_.get(this.props, "selectedProduct.id", -1) === t.id}
                  onChange={() => this.handleChange(null, t.id)}
                />
              }
              label={<Typography variant={"h6"} component={"span"}>{t.name}</Typography>}
            />
            <Typography variant="body2" color="textSecondary">
              <RichContent content={t.ov_description || ""} />
            </Typography>
            <FormControlLabel
              key={t.id}
              value={t.id}
              control={<Radio />}
              className={this.props.classes.formControlLabel}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  renderAvailableProducts = () => {
    let products = [];
    if (!_.isEmpty(this.props.availableProducts)) {
      products = this.props.availableProducts.map((t) => {
        return (
          <Grid item xs={12} md={6} key={t.id}>
            {this.renderProduct(t)}
            {t.ov_link && (
              <p>
                <a target="_blank" rel="noreferrer" href={t.ov_link}>
                  + info
                </a>
              </p>
            )}
          </Grid>
        );
      });
    }
    return products;
  };

  render() {
    const { selectedProduct } = this.props;
    const noAvailProductsTemplate = _.template(
      i18n.t('common:text.contractation_no_available_tariffs')
    );
    return this.props.isFetching ? (
      <LoadingAnimation />
    ) : (
      <div>
        <RadioGroup
          name="product"
          defaultValue={selectedProduct ? selectedProduct.id : ""}
          onChange={this.handleChange}
          className="products-container"
          value={selectedProduct ? selectedProduct.id : ""}
        >
          <Grid container spacing={3}>
            {this.renderAvailableProducts()}
          </Grid>
        </RadioGroup>
        {_.isEmpty(this.props.availableProducts) && (
            <div style={{ color: red[500] }}>
              <RichContent
                content={noAvailProductsTemplate({
                  phone: Settings.organization.phone_crm,
                  phoneLink: Settings.organization.phone_crm.replace(/\s/g, ''),
                })}
              />
            </div>
          )}
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(GasProductSelect);
