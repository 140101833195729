import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../actions/newElectricityContract";
import * as gasActionCreators from "../../actions/newGasContract";
import _ from "lodash";
import queryString from "query-string";

import { Step, Stepper, StepLabel, Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { i18n } from "../../config";

import Settings from "../../settings";
import { collectFormData } from "./form-data";
import { checkDNI, checkScore, sendElectricityLeads, sendGasLeads } from "../../utils/http_functions";

import HelpMessage from "../HelpMessage";

import FormAboutHome from "./FormAboutHome";
import FormAboutYou from "./FormAboutYou";
import FormPayment from "./FormPayment";
import FormAboutContract from "./FormAboutContract";
import FormAboutGasContract from "./FormAboutGasContract";
import Confirm from "./Confirm";
import Confirmed from "./Confirmed";
import Alert from "../Alert";
// import cookies from "../../utils/cookies";


function mapStateToProps(state) {
  return {
    authType: state.auth.type,
    isAuthenticated: state.auth.isAuthenticated,
    electricity: state.newElectricityContract,
    gasProps: state.newGasContract,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    electricityCreators: bindActionCreators(actionCreators, dispatch),
    gasCreators: bindActionCreators(gasActionCreators, dispatch),
  };
}

const NewElectricityContract = (props) => {
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [morosityDetected, setMorosityDetected] = useState(false);
  const [dniInvalid, setDNIInvalid] = useState(false);
  const matchesBreakpointMd = useMediaQuery(theme.breakpoints.up('md'));

  const phoneLink = _.get(Settings, "organization.phone_crm", "").replace(/\s/g, '');
  const phone = _.get(Settings, "organization.phone_crm", "");

  useEffect(() => {
    const { promocode } = queryString.parse(props.location.search, {arrayFormat: 'comma'});

    if (Settings?.features?.promotionalCode && promocode) {
      props.electricityCreators.validateCoupon(promocode);
    }
  }, []);

  let preCompiledSteps = [
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_about_home')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_about_you')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_about_contract')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_about_payment')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_confirmation')}</StepLabel>
    </Step>,
    <Step>
      <StepLabel>{i18n.t('common:text.contractation_confirmed')}</StepLabel>
    </Step>
  ];
    
  if (props.gas) {
    preCompiledSteps.splice(2, 0, 
      <Step><StepLabel>{i18n.t('common:text.contractation_about_gas_contract')}</StepLabel></Step>
    );
  }
  
  if(_.get(Settings, 'newContract.businessesHaltContractation', false) && props.electricity.company) {
    preCompiledSteps.splice(2, props.gas ? 4 : 3);
  }

  const stepLimit = preCompiledSteps.length;

  const stepTabs = (
    <Stepper activeStep={index} orientation={matchesBreakpointMd ? "horizontal" : "vertical" }>
      {preCompiledSteps.map(step => {return step})}
    </Stepper>
  );

  const nextStep = (values) => {
    if (index < stepLimit) {
      let inc = 1;     
      if (!props.gas && index === 3) {
        inc = 1;
      } 
      if (_.get(Settings, 'newContract.businessesHaltContractation', false) && values?.company &&  index === 1) {
        inc = 3;
      }
      setIndex(index + inc);
    }
  };

  const handleGoBack = (values) => {
    if (index > 0) {
      if (morosityDetected) setMorosityDetected(false);
      if (dniInvalid) setDNIInvalid(false);
      
      let inc = 1;
      if (!props.gas && index === 3) {
        inc = 1;
      } 
      if (_.get(Settings, 'newContract.businessesHaltContractation', false) && props.electricity?.company &&  index === 4) {
        inc = 3;
      }
      setIndex(index - inc);
    }
  };

  const shouldSaveLeads = (leadIndex, leadsConsent=false) => {
    return _.get(Settings, "features.leads", false) &&
      (leadIndex === 1 ? leadsConsent : props.electricity.leadsConsent) &&
      index !== stepLimit &&
      leadIndex;
  }

  const sendLeads = async (leadIndex) => {
    const formData = collectFormData(props.electricity);
    const electricityResponse = await sendElectricityLeads({
      FormConfirm: formData,
      step: leadIndex,
    });


    if (props.gas && props.gasProps.cups) {
      const gasFormData = collectFormData({
        ...props.electricity,
        cups: props.gasProps.cups,
        comerOrigin: props.gasProps.comerOrigin,
        accessTariff: props.gasProps.accessTariff,
        selectedProduct: props.gasProps.selectedProduct,
        invoice: props.gasProps.invoice,
        owner: {
          ...props.electricity.owner,
          changeOwner: props.gasProps.changeOwner,
        },
      });
      gasFormData.step = leadIndex;
      if (_.get(electricityResponse, "data.lead_id")) {
        gasFormData.electricityLeadId = electricityResponse.data.lead_id;
      }
      sendGasLeads(gasFormData);
    }
    return electricityResponse?.data.lead_id;
  }

  const handleSubmit = async (values) => {
    const leadIndex = steps[index].leadIndex;
    if (shouldSaveLeads(leadIndex, props.isAuthenticated || values.leadsConsent))
      await sendLeads(leadIndex); // Await for the response to jump to the next step
    nextStep();
  }

  const handleSubmitConfirm = async (values) => {
    const leadIndex = steps[index].leadIndex;
    const formData = collectFormData(props.electricity);
    formData.step = leadIndex;
    const electricityResponse = await sendElectricityLeads({
      FormConfirm: formData,
      step: leadIndex,
    });
    formData.confirm = true;
    props.electricityCreators.confirmNewContract({
      FormConfirm: formData,
      step: leadIndex,
    });

    if (props.gas) {
      const gasFormData = collectFormData({
        ...props.electricity,
        cups: props.gasProps.cups,
        comerOrigin: props.gasProps.comerOrigin,
        accessTariff: props.gasProps.accessTariff,
        selectedProduct: props.gasProps.selectedProduct,
      });
      gasFormData.step = leadIndex;
      if (_.get(electricityResponse, "data.lead_id")) {
        gasFormData.electricityLeadId = electricityResponse.data.lead_id;
      }
      gasFormData.confirm = true;
      props.gasCreators.confirmNewContract(gasFormData);
    }

    nextStep(values);
  }

  const handleAboutYouSubmit = async (values) => {
    if (morosityDetected) setMorosityDetected(false); // Just for UI, make a spasm effect for the alerts
    if (dniInvalid) setDNIInvalid(false);

    const leadIndex = steps[index].leadIndex;

    if (shouldSaveLeads(leadIndex)) { // Will verify if the DNI is valid, and if DNI is not moroso, if one is true the users can't go to the next step.
      const leadId = await sendLeads(leadIndex);
      if (leadId !== undefined) {
        if (_.get(Settings, "contract.isDNIValidationLeadEnabled", false)) {
          const dniResponse = await checkDNI(null, leadId);
          if (dniResponse  && dniResponse.status === 200 && !dniResponse.data['dni_valid']) {
            setDNIInvalid(true);
            return
          }

          const scoreResponse = await checkScore(null, leadId);
          if (scoreResponse.status === 200 && !scoreResponse.data['scoring']) {
            setMorosityDetected(true);
            return
          }
        }
      }
    } 
    if (morosityDetected) setMorosityDetected(false);
    if (dniInvalid) setDNIInvalid(false);
    nextStep(values);

  }

  const steps = [
    {
      leadIndex: 1,
      content: (
        <FormAboutHome
          onSubmit={handleSubmit}
          gas={props.gas}
          selectComerOrigin={_.get(Settings, 'newContract.selectComerOrigin', false)}
        />
      ),
    },
    {
      leadIndex: 2,
      content: (
        <FormAboutYou
          onGoBack={handleGoBack}
          onSubmit={handleAboutYouSubmit}
          gas={props.gas}
        />
      ),
    },
    {
      leadIndex: 3,
      content: (
        <FormAboutContract onGoBack={handleGoBack} onSubmit={handleSubmit} />
      ),
    },
    {
      leadIndex: 4,
      content: <FormPayment onGoBack={handleGoBack} onSubmit={handleSubmit} />,
    },
    {
      leadIndex: 5,
      content: <Confirm onGoBack={handleGoBack} onSubmit={handleSubmitConfirm} />,
    },
    { leadIndex: 5, content: <Confirmed onGoBack={handleGoBack} /> },
  ];

  if (props.gas) {
    steps.splice(2, 0,
      {
        leadIndex: 3,
        content: (
          <FormAboutGasContract
            onGoBack={handleGoBack}
            onSubmit={handleSubmit}
            selectComerOrigin={_.get(Settings, 'newContract.selectComerOrigin', false)}
          />
        ),
      },
    );
  }

  const stepContent = () => {
    return steps[index].content;
  };


  useEffect(() => {

    props.electricityCreators.fetchContractationConfig();
    
    import("../../overrides/hooks/contractation").then(
      ({chatHook}) => {
        if (typeof chatHook === "function" && !!chatHook()) {
          document.body.appendChild(chatHook());
        }  
      }
    );

    if ( index == stepLimit-1 ) {
      // if (cookies.marketingConsentGiven()){
      import("../../overrides/hooks/contractation").then(
        ({trackingHook}) => {
          if (typeof trackingHook === "function" && !!trackingHook()) {
            let content =  trackingHook();
            if (content) {
              content = content.replace("<insert order id value here>", props.electricity.cups);
              document.head.prepend(document.createRange().createContextualFragment(content));
            }
          }  
        }
      );
    }
    // }

    // eslint-disable-next-line
  }, [index]);

  return (
    <div className="form-contractation">
      <Box mb={2}>
        <Typography variant="h4" style={{marginBottom: 10}}>
          {props.gas ? 
          i18n.t('common:text.contractation_dual_title') : 
          i18n.t('common:text.contractation_title')
          }
        </Typography>

        {_.get(Settings, 'newContract.helpMessage', false) &&
          <HelpMessage
            message = {
              i18n.t('common:text.contractation_help_message', { phone: phone, phoneLink: phoneLink, })
            }
          />
        }
      </Box>
      {stepTabs}

      {stepContent()}
      
      { morosityDetected && 
        <Alert severity={"error"}>
          {i18n.t("common:text.contractation_leads_bad_score")}
          <br></br>
          {i18n.t("common:text.contractation_leads_bad_score_thanks")}
        </Alert>
      }
      { _.get(Settings, "contract.isDNIValidationLeadEnabled", false) && 
          dniInvalid &&
        <Alert severity={"error"}>
          {i18n.t("common:text.contractation_leads_dni_invalid")}
        </Alert>
      }
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewElectricityContract);
