import React, {useState} from 'react';
import { i18n } from "../../config";
import _ from "lodash";

import { Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, FormHelperText } from "@material-ui/core";

const CurrentActiveContract = ({
  input: { onChange, value },
  meta, onUpdate
}) => {
  const [activeValue, setActiveValue] = useState(value);

  const handleChange = (event) => {
    setActiveValue(event.target.value);
    if (!_.isEmpty(event.target.value)) {
      if (onChange) {
        onChange(event.target.value);
      }
      if (typeof onUpdate === "function") {
        onUpdate(event.target.value);
      }
    }
  }

  return (
    <FormControl component="fieldset" error={!!(meta.touched && !meta.valid)} style={{marginTop: 20}}>
      <FormLabel component="legend">{i18n.t('common:text.contractation_current_active_contract_question')}*</FormLabel>
      <RadioGroup row aria-label="current-active-contract" name="activeContract" value={activeValue} onChange={handleChange}>
        <FormControlLabel value="yes" control={<Radio />} label={i18n.t('common:text.generic_yes')} />
        <FormControlLabel value="no" control={<Radio />} label={i18n.t('common:text.generic_no')} />
      </RadioGroup>
      <FormHelperText>{(!!(meta.touched && !meta.valid)) ? meta.error : ''}</FormHelperText>
    </FormControl>
  );

}

export default CurrentActiveContract;
