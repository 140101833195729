import React from "react";
import { Popover, Switch, FormControlLabel, Badge, FormControl, InputLabel, Select,
          Card, CardActions, CardMedia, TextField, MenuItem, Box, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";

const allowEditPower = false;


class Appliance extends React.Component {
  constructor(props) {
    super(props);
    let power = props.options[0].power;
    this.state = {
      enabled: false,
      num: props.areaDependent ? props.area : props.num || 1,
      power: power,
      name: props.name,
      img: props.img,
      totalPower: 0,
      popOverOpen: false,
      popOverAnchor: null,
    }
  }

  componentWillReceiveProps(props) {
    const state = {};
    if (props.areaDependent) {
      if (props.area) {
        state.num = props.area;
      }
    }
    this.setState(state, this.totalPower);
  }

  totalPower = () => {
    const cofSimul = this.props.cofSimulDependent ? this.props.cofSimul : 1;
    const totalPower = Math.round((this.props.base || 0) + (
      this.state.num * this.state.power * this.state.enabled * 100 * cofSimul)
    ) / 100;
    if (totalPower !== this.state.totalPower) {
      this.setState({totalPower});
    }
  }

  handleChangeNum = (num) => {
    this.setState({num}, this.totalPower)
  }

  handleChangePower = (power) => {
    this.setState({power}, this.totalPower)
  }

  handleToggle = async (value) => {
    await this.setState({enabled: value}, this.totalPower);
    if (this.props.onChange) {
          this.props.onChange({...this.state});
        }
  }

  render() {
    const options = this.props.options.map(opt => <MenuItem key={opt.name} value={opt.power}>{opt.name}</MenuItem>)

    return (
      <Card
        style={{
          opacity: this.state.enabled ? 1 : 0.5,
          display: "flex",
          flexDirection: "column",
        }}
        className="appliance"
      >
        <Box p={2}>
          <Typography variant="h6">{this.props.name}</Typography>
        </Box>
        <CardMedia style={{ margin: '20px 20px 0 20px', flexGrow: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div style={{ alignSelf: "flex-end" }}>
              <FormControlLabel
                labelPlacement="start"
                style={{ marginLeft: 0, marginRight: 0 }}
                control={
                  <Switch
                    checked={this.state.enabled}
                    onChange={(event, value) => this.handleToggle(value)}
                  />
                }
              />
              {this.props.hint && (
                <Help
                  onClick={(event) => {
                    this.setState({
                      popOverOpen: true,
                      popOverAnchor: event.currentTarget,
                    });
                  }}
                />
              )}
              {this.props.hint && (
                <Popover
                  anchorEl={this.state.popOverAnchor}
                  open={this.state.popOverOpen}
                  onClose={() => this.setState({ popOverOpen: false })}
                >
                  <div style={{ padding: 5, maxWidth: 300 }}>
                    {this.props.hint}
                  </div>
                </Popover>
              )}
            </div>
            <div style={{alignSelf: 'flex-end'}}>
              {this.state.enabled && this.props.optionsLabel && (
                <FormControl style={{ width: 150 }}>
                  <InputLabel shrink htmlFor="">
                    {this.props.optionsLabel}
                  </InputLabel>
                  <Select
                    onChange={(event, index, value) =>
                      this.handleChangePower(event.target.value)
                    }
                    value={this.state.power}
                  >
                    {options}
                  </Select>
                </FormControl>
              )}
              {this.state.enabled && (
                <div>
                  {this.props.multiple && (
                    <TextField
                      label={this.props.multipleLabel || "Quantitat"}
                      type="number"
                      min={1}
                      style={{ width: 150 }}
                      readOnly={!this.state.enabled}
                      value={this.state.num}
                      onChange={(event) =>
                        this.handleChangeNum(event.target.value)
                      }
                    />
                  )}
                  {allowEditPower && (
                    <TextField
                      label="Potència"
                      type="number"
                      min={0.1}
                      style={{ width: 80 }}
                      readOnly={!this.state.enabled}
                      value={this.state.power}
                      onChange={(event) =>
                        this.handleChangePower(event.target.value)
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </CardMedia>
        <CardActions style={{ flexGrow: 2 }}>
          <Badge
            badgeContent={`${this.state.totalPower
              .toString()
              .replace(".", ",")} kW`}
            color={"primary"}
            style={{ alignSelf: "flex-end" }}
          >
            <img
              src={this.props.img}
              style={{ width: 100 }}
              onClick={() => this.handleToggle(!this.state.enabled)}
              alt=""
            />
          </Badge>
        </CardActions>
      </Card>
    );
  }
}

export default Appliance;
