import { useState } from "react";
import { Button, Grid, Drawer, Typography, IconButton } from "@material-ui/core"

import { Close, ShareOutlined } from '@material-ui/icons';

import {
  WhatsappShareButton, WhatsappIcon,
  FacebookShareButton, FacebookIcon,
  TelegramShareButton, TelegramIcon,
} from "react-share";
import { useTranslation } from "react-i18next";

const shareEnabled = navigator.share;

const ShareDrawer = (props) => {
  const { t } = useTranslation();

  return (
    <Drawer open={props.visible} anchor="bottom" onClose={props.onClose} style={{height: "100%"}}>
      <Grid container>
        <Grid container item justifyContent="space-between">

          <Grid item>
            <Typography variant="h5" style={{padding: "10px"}}>{t('common:text.share_more')}</Typography>
          </Grid>

          <Grid item>
            <IconButton aria-label="close" onClick={props.onClose}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>
      
      <Grid item container style={{padding: "0 0 15px 15px"}}>
        <Grid item style={{marginRight: "10px"}}>
          <WhatsappShareButton url={props.url}>
            <WhatsappIcon round/>
          </WhatsappShareButton>
        </Grid>
        
        <Grid item style={{marginRight: "10px"}}>
          <FacebookShareButton url={props.url}>
            <FacebookIcon round/>
          </FacebookShareButton>
        </Grid>
        
        <Grid item>
          <TelegramShareButton url={props.url}>
            <TelegramIcon round/>
          </TelegramShareButton>
        </Grid>
        
      </Grid>
    </Drawer>
  )
}

const shareNavigator = async (url) => {
  try {
    await navigator.share({'url': url});
  } catch (err) {
    console.error(err);
  }
}

export const Share = ({url, ...rest}) => { //Passar paràmetres del botó quan sigui un TS
  const [ visible, setVisible ] = useState(false);

  const share = () => {
    if (shareEnabled) {
      shareNavigator(url);
    } else {
      setVisible(true);
    }
  }
  
  return <>
      <Button startIcon={<ShareOutlined />} onClick={share} {...rest}/>
      {!shareEnabled && <ShareDrawer visible={visible} onClose={() => setVisible(false)} url={url}/>}
    </>
}