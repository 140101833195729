import Settings from "../../settings";

import { 
  Container, Button, Link, Typography, Box, Paper, Grid
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CookieAcceptDialog = (props) => {
  const { t } = useTranslation();
  return (
    <Box sx={{position: "fixed", bottom: 0, width: "100%"}}>
      <Paper elevation={24} style={{padding: 30, borderRadius: 0}}>
        <Typography variant="h4">{t('common:text.cookie_consent_title')}</Typography>
        <Typography>{t('common:text.cookie_consent_text')}</Typography>
        <Container>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} style={{textAlign:"center"}}>
              <Link variant="button" href={Settings.links.cookies} target="_blank" rel="noreferrer">{t('common:text.cookie_policy_text')}</Link>
            </Grid>
            <Grid item>
              <Button
                color={'primary'}
                variant={'contained'}
                className="container-element"
                onClick={() => {props.acceptCookies("all")}}
              >
                {t('common:text.cookie_accept_all_text')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                className="container-element"
                onClick={props.personalizeCookies}
              >
                {t('common:text.cookie_decide_which_text')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Box>
  );
};

export default CookieAcceptDialog;