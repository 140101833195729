import { i18n } from "../config/i18n";
import { features } from "../settings";

export const locale_code = i18n.t('common:locale.code');
export const login_url = i18n.t('common:url.login');
export const connect_url = i18n.t('common:url.connect');
export const home_url = i18n.t('common:url.contracts');

export const contraction_url = (() => {
    switch (features.newContractType) {
      case 'electricitat':
        return i18n.t('common:url.newElectricityContract');
      case 'gas':
        return i18n.t('common:url.newGasContract');
      case 'dual':
        return i18n.t('common:url.newDualContract');
      default:
        return i18n.t('common:url.newElectricityContract');
    }
  })()
