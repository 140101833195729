import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MoveVirtualBatteryStep1 from "./MoveVirtualBatteryStep1";
import MoveVirtualBatteryStep2 from "./MoveVirtualBatteryStep2";
import { i18n } from "../../../../config";
import useMoveVirtualBatteryContext from "../useMoveVirtualBatteryContext";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import MoveVirtualBatteryStep3 from "./MoveVirtualBatteryStep3";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getStepContent(step, nextStepCallback) {
  switch (step) {
    case 0:
      return <MoveVirtualBatteryStep1 nextStepCallback={nextStepCallback} />;
    case 1:
      return <MoveVirtualBatteryStep2 nextStepCallback={nextStepCallback} />;
    case 2:
      return <MoveVirtualBatteryStep3 nextStepCallback={nextStepCallback} />;
    case 3:
      return (
        <CircularProgress
          color="secondary"
          style={{ marginRight: 10 }}
          size={25}
        />
      );
    default:
      return "Unknown step";
  }
}

export const HorizontalLinearStepper = ({ onClose, onSubmit, disabled }) => {
  const { selectedBatteryId } = useMoveVirtualBatteryContext();
  const { waitingChangeRequest } = useSelector((state) => state.bateriaVirtual);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    i18n.t("common:text.select_battery"),
    i18n.t("common:text.virtual_battery_modify_percentage"),
    i18n.t("common:text.virtual_battery_move_step_3_title_finalitza"),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    setActiveStep(3);
    onSubmit();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep, handleNext)}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={onClose}
                  disabled={activeStep === 3}
                >
                  {i18n.t("common:text.payment_form_cancel")}
                </Button>
              </div>
              <div>
                <Button
                  disabled={activeStep === 0 || activeStep === 3}
                  onClick={handleBack}
                  className={classes.button}
                >
                  {i18n.t("common:text.virtual_battery_move_stepper_anterior")}
                </Button>

                {activeStep < 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={!selectedBatteryId}
                  >
                    {i18n.t("common:text.virtual_battery_move_stepper_seguent")}
                  </Button>
                )}
                {activeStep === 2 && (
                  <Button
                    key={"send"}
                    color={"primary"}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={disabled}
                  >
                    {waitingChangeRequest ? (
                      <CircularProgress
                        color="secondary"
                        style={{ marginRight: 10 }}
                        size={25}
                      />
                    ) : null}
                    {i18n.t("common:text.virtual_battery_move_stepper_confirm")}
                  </Button>
                )}
              </div>
            </Box>
          </div>
      </div>
    </div>
  );
};

export default HorizontalLinearStepper;
