import { Box, Paper, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { i18n } from "../../../../config";
import React from "react";
import useVirtualBattery from "../../useVirtualBattery";
import BatterySelectorItem from "./BatterySelectorItem";

export const BatterySelector = ({ excludeBatteryId, selectedBatteryId, onSelected }) => {
  const { view_contract, items } = useSelector((state) => state.contracts);
  const bateriaVirtualTool = useVirtualBattery();
  const batteriesDistribution = bateriaVirtualTool.getBatteriesDistribution({
    contracts: items,
    excludeContractName: view_contract.name,
    excludeBatteryId: excludeBatteryId,
  });

  const renderBateriaVirtualSelector = () => {
    const drawedVirtualBatteryItems = [];
    for (let [batteryId, batteryDistribution] of Object.entries(batteriesDistribution)) {
      drawedVirtualBatteryItems.push(
        <BatterySelectorItem
          key={batteryId}
          batteryDistribution={batteryDistribution}
          onClick={(selectedBatteryDistribution) => onSelected?.(batteryDistribution)}
          selected={selectedBatteryId == `${batteryId}`}
        />
      );
    }

    return (
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem"
      }}>
       {drawedVirtualBatteryItems}
      </Box>
    );
  };

return (
  <Box>
    <Typography variant="h6">
      {i18n.t("common:text.select_battery")}:
    </Typography>
    <Paper
      style={{
        padding: "1rem",
        overflowY: "auto",
        maxHeight: "35vh",
      }}
      variant="outlined"
    >
      {renderBateriaVirtualSelector()}
    </Paper>
  </Box>
);
};

export default BatterySelector;
