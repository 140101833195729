import React, { useState } from "react";
import _ from "lodash";
import { locale_code } from "../../constants/i18n";
import Settings from "../../settings";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { i18n } from "../../config";

import { Button,
  Dialog, DialogContent } from '@material-ui/core';
import { Payment } from "@material-ui/icons"

const stripeKey = _.get(Settings, "stripe.key", null);
const stripePromise = stripeKey && loadStripe(stripeKey, { locale: "es" });

const StripePaymentDialog = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    if (typeof props.onPaymentSuccessful === "function") {
      props.onPaymentSuccessful();
    }
  };

  if (!_.get(Settings, "invoices.enablePayment", false)) {
    return null;
  }
  const { invoice } = props;
  const localizedDebt = invoice.amount_debt.toLocaleString(locale_code);

  return (
    <div>
      <Button
        color={'primary'}
        variant={'contained'}
        style={{
          marginTop: "5px",
          marginLeft: "5px",
          marginRight: "5px",
          overflow: "hidden",
        }}
        onClick={handleOpen}
      >
        {<Payment />}
        {_.template(i18n.t('common:text.payment_form_pay'))({
          amount: `${localizedDebt} €`,
        })}
      </Button>

      <Dialog
        open={open}
        disableBackdropClick={true}
      >
        <DialogContent style={{ maxWidth: "400px" }}>
          <Elements stripe={stripePromise}>
            <StripePaymentForm {...props} onSuccess={handleSuccess} onCancel={handleCancel} />
          </Elements>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StripePaymentDialog;
