/* eslint camelcase: 0, no-underscore-dangle: 0 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../actions/auth';

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

class LogoutView extends React.Component {

    componentDidMount() {
      this.props.logoutAndRedirect();
    }

    render() {
      return null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);