import { Skeleton } from "@material-ui/lab";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanners } from "../../actions/banners";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";


/**
 * Feature component
 * 
 * Responsible for rendering a dialog with a slider to edit percentage values related to the distribution of virtual battery percentages
 * 
 * - Delegate the rendering of percentage distribution to the `VirtualBatteryDistributedPercentageDetails` component.
 * 
 */
const Banner = ({code}) => {
  const dispatch = useDispatch();

  const banners = useSelector((state) => state.banners);
  const {i18n} = useTranslation();
  let bannerContent = banners.banners[code];

  useEffect(() => {
    if (!banners.banners[code]){
      dispatch(fetchBanners(code))
    }
  }, []);

  useEffect(() => {
    dispatch(fetchBanners(code))
  }, [i18n.language]);

  const detectContentType = (base64String) => {
    let binaryString;
    try {
      binaryString = atob(base64String.slice(0, 30));
    }
    catch {
      return null;
    }
     // Decode a portion of the base64
    const signatures = {
      "image/png": "\x89PNG\r\n\x1a\n",
      "image/jpeg": "\xff\xd8\xff",
      "image/gif": "GIF87a",
      "image/webp": "RIFF"
    };

    for (const [type, signature] of Object.entries(signatures)) {
      if (binaryString.startsWith(signature)) {
        return type;
      }
    }
    
    return null;
  };

  if (bannerContent) {
    const contentType = detectContentType(bannerContent);
    if (contentType) {
      bannerContent = `data:${contentType};base64,${bannerContent}`;
      bannerContent = <img src={bannerContent} alt="Rendered from base64" />
    } else {
      bannerContent = <div dangerouslySetInnerHTML={{ __html: bannerContent }} />;
    }
  }
  
  return (
    <Box height="100%">
      {!banners.fetching[code]?
        <Box>
          {bannerContent}
        </Box>
      :
        <Skeleton variant='rect' animation="wave" width="100%" height="100%"/>}
    </Box>
  );
}

export default Banner;