import React from "react";
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export const powerValid = (value) => {
  return value && !Number.isNaN(Number(value)) && Number(value) > 0;
};

const PowerField = (props) => {
  return (
    <NumberFormat
      style={{ width: "100%", maxWidth: "300px" }}
      customInput={TextField}
      suffix=" kW"
      type="text"
      allowedDecimalSeparators={[",", "."]}
      decimalSeparator=","
      thousandSeparator=" "
      isNumericString={true}
      {...props}
    />
  );
}

export default PowerField;