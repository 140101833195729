import { IconButton } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { i18n } from "../../config";
import { data_download_api_resource } from "../../utils/http_functions";
import { Alert } from "@material-ui/lab";
import { useState } from "react";

const DownloadAttachmentButton = ({downloadURL}) => {
    const [hasError, setHasError] = useState(false);

    const handleDownloadAttachment = () => {
        data_download_api_resource(null, downloadURL, 2).then(response => {
            // Check for valid response object and non-empty filename

                if (!response) { //|| !filename) {
                    console.error('Invalid response or filename provided.');
                    return; // Prevent further execution
                }

                const contentDisposition = response.headers.get('content-disposition');
                let filename = "";
                // Extract filename if available from content-disposition header
                if (contentDisposition) {
                    try {
                    filename = contentDisposition.split(';').find(param => param.trim().startsWith('filename'))
                        .split('=')[1].trim().replace(/^["']|["']$/g, ''); // Remove surrounding quotes
                    } catch (e) {
                    console.warn('Failed to extract filename from content-disposition:', e);
                    }
                }

                // Create Blob object for the downloaded data
                const blob = new Blob([response.data], { type: response.headers.get('content-type') || 'application/octet-stream' });

                // Create temporary object URL for download
                const url = window.URL.createObjectURL(blob);

                // Create download link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                // Handle link click behavior and cleanup
                link.addEventListener('click', () => {
                    setTimeout(() => {
                    // Remove the object URL after a short delay to avoid race conditions
                    window.URL.revokeObjectURL(url);
                    link.remove();
                    }, 100);
                });
          }).catch(() => {
            setHasError(true);
          });
      }
    const drawed = {
        true: (
            <Alert style={{cursor: "pointer"}} severity={"error"} onClick={() => setHasError(false)}>
            </Alert>
        ),
        false: (
            <IconButton edge="end" aria-label={i18n.t('common:text.attachment_download')} onClick={() => handleDownloadAttachment()}>
                <DownloadIcon />
            </IconButton>
        )
    }

    return drawed[hasError];
}

export default DownloadAttachmentButton;