import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import Alert from "../components/Alert";
import * as actionCreators from '../actions/auth';
import Settings from '../settings';
import LoginIcon from '@material-ui/icons/ExitToApp';

import { LoadingAnimation } from './LoadingAnimation';
import { i18n } from "../config";
import { getLang } from "../i18n"

import { makeStyles } from "@material-ui/core/styles";

import queryString from "query-string";

import * as auth from '../utils/auth';

function mapStateToProps(state) {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    statusText: state.auth.statusText,
    statusType: state.auth.statusType
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const styles = {
  main: {
    marginTop: 50,
    padding: 50,
    width: '100%',
    textAlign: 'center',
    display: 'inline-block',
  },
  logo: {
    paddingLeft: 'auto',
    paddingRight: 'auto',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  logoImage: {
    maxWidth: '300px',
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 300,
    },
  },
}));

const ConnectView = (props) => {

  const classes = useStyles();

  const getNext = () => {
    let next = '/';

    if (
      "" !== props.location.search && // url has additional query params 
      props.location.search.includes(i18n.t('common:url.next')) // additional query params include keyword to redirect to another page after login
    ) {
      let next_param = props.location.search.split(i18n.t('common:url.next') + "=").pop().split('&')[0];
      next = next_param ? next_param : "/";
    }

    return next;
  };

  const access = (e, token = false, next = false) => {
    e.preventDefault();
    performAccess(token, next);
  }

  const performAccess = (token = false, next = false, promocode = false) => {
    let redirect;
    if (next) {
      redirect = next;
    } else {
      redirect = getNext();
    }
    
    if (token) {
      props.loginToken(token, redirect);
    } else {
      retrieveCode(redirect, promocode);
    }
  }

  /**
   * Login via id_client parameter. On login success, the user will be
   * redirected to contraction_url (see settings).
   * @param {string} clientId Parameter id_client
   * @param {string} secret Parameter secret
   */
  const performAccessClientId = (clientId, secret) => {
    props.loginClientId(clientId, secret);
  }

  const retrieveCode = (redirect, promocode) => {
    // Redirect to the OpenID provider to register new user or login to get an access code.
    const lang = getLang().split('_')[0];
    const { oidc } = Settings;
    const { authorize } = oidc.endpoints;
    let params = {};
    if (authorize.extraParamsVars) {
      if (authorize.extraParamsVars.hasOwnProperty('lang')) {
        params[authorize.extraParamsVars.lang] = lang;
      }
    }
    if (authorize.extraParams) {
      params = { ...params, ...authorize.extraParams };
    }
    let statePayload = {"qs": {}};
    if (redirect) {
      statePayload["qs"]['redirect'] = redirect;
    }
    if (promocode) {
      statePayload["qs"]['promocode'] = promocode;
    }
    const extraParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    auth.generateAndSaveState(statePayload).then(state => {
      const nonce = auth.generateAndSaveNonce()
      window.location = `${oidc.providerUrl}${authorize.resource}?client_id=${oidc.clientId}&response_type=${authorize.response_type}&scope=${authorize.scope}&state=${state}&nonce=${nonce}&redirect_uri=${authorize.redirectUrl}&${extraParams}`;
    });
  }

  useEffect(() => {
    // if we have a code parameter in the query, we should send it to the OV API (to retrieve 
    // a token).
    const { code, state, token, id_client, secret, promocode } = queryString.parse(props.location.search);
    const next = getNext();
    
    if (code) {
      props.loginOpenId(code, state, next);
    } else if (id_client && secret) {
      performAccessClientId(id_client, secret);
    } else {
      // Login even if it has no token
      performAccess(token, null, promocode);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Paper style={styles.main}>
          <div>
            <div id="image" style={styles.logo}>
              <img src="/images/logo.png" alt="Logo" style={styles.logoImage} />
            </div>
          </div>
          {props.isAuthenticating ?
          <LoadingAnimation />
          :
          <form className={classes.root}>
            <div>
              <h2>{i18n.t('common:text.connect_view_title')}</h2>
              {props.statusText && (
                <Alert severity={props.statusType}>
                  {props.statusText}
                </Alert>
              )}

              <div style={{ marginTop: 15 }}>
                <Button
                  style={{ marginLeft: 1, marginRight: 1 }}
                  label={i18n.t('common:text.connect_view_button_access')}
                  onClick={access}
                  color="primary"
                  variant="contained"
                  startIcon={<LoginIcon />}
                >
                  {i18n.t('common:text.connect_view_button_access')}
                </Button>
              </div>
            </div>
          </form>
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectView);

ConnectView.propTypes = {
  loginOpenId: PropTypes.func,
  loginClientId: PropTypes.func,
  isAuthenticating: PropTypes.bool,
  statusText: PropTypes.string,
  statusType: PropTypes.string,
};
