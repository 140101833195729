import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../ErrorMessage";

import { Button, Select, FormControl, InputLabel, MenuItem,
  Dialog, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';

import {
  data_update_api_resource,
  data_fetch_api_resource,
} from "../../utils/http_functions";
import { i18n, dayjs } from "../../config";
import { locale_code } from "../../constants/i18n";

class CustomizeDoubleTariff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,

      selectedPeriod: 0,
      dialogOpen: false,
      error: false,
    };
    dayjs.locale(locale_code);
  }

  fetchActual = async () => {
    const response = await data_fetch_api_resource(
      null,
      `contract/${this.props.contractId}/double-tariff/`
    );

    if (response && !_.isEmpty(response.data)) {
      const { data } = response;
      this.setState({
        startMonth: data.startMonth,
        startYear: data.startYear,
        endMonth: data.endMonth,
        endYear: data.endYear,
      });
    }
  };

  handleRequestClose = async () => {
    this.setState({ dialogOpen: false });
  };

  handleRequestOpen = async () => {
    // Fetch available options
    this.setState({ error: false });
    try {
      const response = await data_fetch_api_resource(
        null,
        `contract/${this.props.contractId}/available-double-tariffs/`
      );

      if (response && !_.isEmpty(response.data)) {
        const periods = response.data;
        this.setState({
          periods,
        });
      }
    } catch (error) {
      this.setState({ error: true });
      // TODO: Add sentry
    }

    this.setState({ dialogOpen: true });
  };

  handleChangePeriod = (event, value) => {
    this.setState({ selectedPeriod: event.target.value });
  };

  handleUpdatePeriod = async () => {
    this.setState({ error: false, sending: true });
    const { periods, selectedPeriod } = this.state;
    console.log(
      "Selected value: " +
        selectedPeriod +
        " - " +
        JSON.stringify(periods[selectedPeriod])
    );
    const selectedPeriodObj = periods[selectedPeriod];
    try {
      await data_update_api_resource(
        null,
        `contract/${this.props.contractId}/double-tariff/`,
        periods[selectedPeriod]
      );
      this.setState({ sending: false });
      this.fetchActual();
      if (this.props.onUpdated) {
        this.props.onUpdated(selectedPeriodObj);
      }
    } catch (error) {
      // TODO: Add sentry
      this.setState({ error: true });
    } finally {
      this.setState({ dialogOpen: false });
    }
  };

  componentDidMount() {
    this.fetchActual();
  }

  formatPeriod = (startMonth, startYear, endMonth, endYear) => {
    let period = "";
    if (startMonth !== null) {
      const startDate = dayjs()
        .year(startYear)
        .month(startMonth - 1)
        .date(1)
        .format("MMMM YYYY");
      const endDate = dayjs()
        .year(endYear)
        .month(endMonth - 1)
        .date(1)
        .format("MMMM YYYY");
      period = `${startDate} - ${endDate}`;
    }
    return period;
  };

  render() {
    const {
      startMonth,
      startYear,
      endMonth,
      endYear,
      dialogOpen,
      periods,
      error,
    } = this.state;

    if (typeof startMonth == "undefined" || startMonth === null || startMonth === false) {
      return null;
    }

    let period = this.formatPeriod(startMonth, startYear, endMonth, endYear);
    let sending = false;

    let periodEntries = [];
    if (!_.isEmpty(periods)) {
      periodEntries = periods.map((p, index) => {
        return (
          <MenuItem
            key={index}
            value={index}
          >
            {this.formatPeriod(
              p.startMonth,
              p.startYear,
              p.endMonth,
              p.endYear
            )}
          </MenuItem>
        );
      });
    }

    return (
      <span>
        {period}
        <Button
          variant={'text'}
          style={{ marginLeft: "1rem" }}
          color={'primary'}
          onClick={this.handleRequestOpen}
        >
          {i18n.t('common:text.contract_modification')}
        </Button>

        <Dialog
          open={dialogOpen}
          disableBackdropClick={true}
          onClose={this.handleRequestClose}
          scroll={'body'}
        >
          <DialogTitle>
            {i18n.t('common:text.customize_double_tariff_title')}
          </DialogTitle>
          <DialogContent>
            <div>
              <FormControl style={{ width: "100%", maxWidth: "300px" }}>
                <InputLabel shrink htmlFor="">
                  {i18n.t('common:text.customize_double_tariff_label')}
                </InputLabel>
                <Select
                  onChange={this.handleChangePeriod}
                  value={this.state.selectedPeriod || 0}
                  autoWidth={true}
                >
                  {periodEntries}
                </Select>
              </FormControl>
            </div>
            <div>{error && <ErrorMessage />}</div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                variant={'contained'}
                style={{ marginRight: "1rem" }}
                onClick={this.handleRequestClose}
                disabled={sending}
              >
                {i18n.t('common:text.customize_double_tariff_close')}
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={this.handleUpdatePeriod}
                disabled={sending}
              >
                {sending ? <CircularProgress size={25} /> : null}
                {i18n.t('common:text.customize_double_tariff_update')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default CustomizeDoubleTariff;

CustomizeDoubleTariff.defaultProps = {
  readonly: false,
};

CustomizeDoubleTariff.propTypes = {
  readonly: PropTypes.bool,
  contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
