import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/contractsGas';
import { debug } from '../utils/debug';
import { locale_code } from '../constants/i18n';
import Settings from "../settings";
import { i18n } from "../config";

import { dispatchNewRoute} from '../utils/http_functions';

import { ContentHeader } from './ContentHeader';

import { Notification } from './Notification';
import { LoadingAnimation } from './LoadingAnimation';
import { SmartTable } from './SmartTable';

import Button from '@material-ui/core/Button';
import { ArrowForward, ArrowBack, Receipt, ZoomIn, ErrorOutline } from '@material-ui/icons';


function mapStateToProps(state) {
    const contracts = state.contractsGas;
    return {
        data: contracts,
        offset: contracts.offset_items,
        page_items: contracts.page_items,
        token: state.auth.token,
        loaded: contracts.loaded,
        isFetching: contracts.isFetching,
        message_text: contracts.message_text,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

const style = {
    buttonAdd: {
        marginRight: 20,
    },
    buttonPosition: {
        textAlign: 'right',
    },
    table: {
        marginTop: 20,
    },
};

class ContractsViewGas extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            message_text: null,
            offset: 0,
            page_items: _.get(Settings, "contracts.itemsPerPage", 100),
        };
    }

    componentDidMount() {
        if (_.isEmpty(this.props.data.items) && !this.props.loaded) {
            this.fetchData();
        }
    }

    fetchData(initial=true, offset=0, filter=null) {
        const token = this.props.token;
        this.props.fetchContracts(token, true, initial, offset, filter);
    }

    refreshData() {
        this.fetchData(false);
        this.setState({
            message_open: true,
        });
    }

    addContracts() {
        dispatchNewRoute(i18n.t('common:url.newGasContract'));
    }

    goToInvoice = (contract) => {
         this.props.filterContractsInvoices(contract);
    };

    goToContract = (id) => {
        const contract = this.props.data.items.filter((c) => c.name === id)[0];
        this.props.viewContract(contract);
        dispatchNewRoute(i18n.t('common:url.contracts_gas'))
    };

    previous_page = () => {
        const offset = this.props.offset - this.props.page_items;
        this.setState({offset: offset});
        this.fetchData(false, offset);
    };

    next_page = () => {
        const offset = this.state.offset + this.state.page_items;
        this.setState({offset: offset});
        this.fetchData(false, offset);
    };

    onSearch = (searchText) => {
        this.fetchData(false, 0, searchText);
    };

    JSON_to_arrays(contracts) {

        const header = [
            {title: ''},
            { title: i18n.t('common:text.contracts_contract') },
            { title: i18n.t('common:text.contracts_cups') },
            { title: i18n.t('common:text.contracts_direction') },
            { title: i18n.t('common:text.contracts_product') },
            { title: i18n.t('common:text.contracts_tariff') },
            { title: i18n.t('common:text.contracts_state') },
            { title: i18n.t('common:text.contracts_payment') },
            { title: i18n.t('common:text.contracts_actions') }
        ];

        // True if debt is 0 on all contracts.
        let noDebt = true;
        for (const [contract] of Object.entries(contracts)) {
            if(contract.amount_debt > 0){
                noDebt = false;
            }
        }

        // Remove payment header column if there is no debt to show.
        if (noDebt) header.splice(7, 1);

        const goToInvoice = this.goToInvoice;
        const goToContract = this.goToContract;

        
        //const content = contracts.map( function(contract, index) {
        const content = [];
        contracts.forEach((contract) => {
            const invoices_button = (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={!contract.name}
                    onClick={() => goToInvoice(contract)}
                    title="Ver facturas relacionadas"
                    style={{marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden'}}
                >
                    {<Receipt/>}
                    {i18n.t('common:text.contracts_view_invoices_button_button')}
                </Button>
            );

            const contract_button = (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => goToContract(contract.name)}
                    disabled={!contract.name}
                    title="Ver detalle del contrato"
                    style={{marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden'}}
                >
                    {<ZoomIn/>}
                    {i18n.t('common:text.contracts_view_contracts_button_button')}
                </Button>
            );

            let contractStatus;
            switch (contract.state) {
                case 'esborrany':
                        contractStatus = i18n.t('common:text.contract_status_draft');
                        if (!contract.sign_date) {
                            contractStatus = i18n.t('common:text.contract_status_signature_pending');
                        }
                        break;
                case 'activa':
                        contractStatus = i18n.t('common:text.contract_status_open');
                        break;
                case 'baixa':
                        contractStatus = i18n.t('common:text.contract_status_closed');
                        break;
                default:
                        contractStatus = i18n.t('common:text.contract_status_closed');
                        break;
            }

            const hasDebt = contract.amount_debt > 0;
            const contractPayment = hasDebt ? `${i18n.t('common:text.contract_debt')}  (${contract.amount_debt.toLocaleString(locale_code)} €)` : '';

            const values = [
                <div>{hasDebt ? <ErrorOutline style={{color: '#f00'}}/> : null}</div>,
                contract.name,
                contract.cups.name,
                `${contract.cups.street} - ${contract.cups.city}`,
                (contract.pricelist && contract.pricelist.name) ? contract.pricelist.name : "",
                contract.tariff.name,
                contractStatus,
                contractPayment,
              <div>
                  {invoices_button}
                  {contract_button}
              </div>
            ];
            // Remove payment column if there is no debt to show.
            if (noDebt) values.splice(7, 1);

            content.push(values);
        });

        return {
            header,
            content
        };
    }

    render() {
        const {data, loaded} = this.props;
        const contracts = data.items || [];

        const adaptedData =
            (loaded) &&
                this.JSON_to_arrays(contracts);

        const start_items = data.offset_items;
        const end_items = Math.min(start_items + data.page_items, data.total_items);

        return (
            <div>
                <Notification
                    message={this.props.message_text}
                    open={this.state.message_open}
                />

        		<ContentHeader
        		    title={i18n.t('common:text.contracts_view_title_gas')}
        		    addButton={_.get(Settings, "features.newGasContractation", false)}
        		    addClickMethod={() => this.addContracts()}

        		    refreshButton={true}
        		    refreshClickMethod={() => this.refreshData()}
                    onSearch={this.onSearch}
                />
            {
                this.props.loaded?
                (contracts.length > 0)?
                    (
                        <div style={style.table}>
                            <SmartTable
                                header={adaptedData.header}
                                data={adaptedData.content}
                            />

                        </div>
                    ) 
                :
                    (
                        <h4>{i18n.t('common:text.contracts_view_empty_list_gas')}</h4>
                    )
                :
                    <div>
                        <LoadingAnimation />
                    </div>
                }
                {(data.page_items < data.total_items) &&
                    <div style={{ textAlign: "center" }}>
                        {(start_items > 0) &&
                            <Button
                                variant={'text'}
                                onClick={() => this.previous_page()}
                                disabled={data.isFetching}
                            >
                                {<ArrowBack />}
                                {"Anterior"}
                            </Button>
                        }
                        <span>Mostrando de {start_items} a {end_items} de {data.total_items}</span>
                        {(end_items <= data.total_items) &&
                            <Button
                                variant={'text'}
                                onClick={() => this.next_page()}
                                disabled={data.isFetching}
                            >
                                {"Siguiente"}
                                {<ArrowForward />}
                            </Button>
                        }
                    </div>
                }
                {debug(contracts)}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsViewGas);

ContractsViewGas.propTypes = {
    fetchContracts: PropTypes.func,
    loaded: PropTypes.bool,
    data: PropTypes.any,
    token: PropTypes.string,
};
