import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_OK, CHANGE_PASSWORD_KO, CHANGE_PASSWORD_INI } from '../constants'
import { data_update_api_resource, dispatchNewRoute } from '../utils/http_functions'
import { parseJSON } from '../utils/misc'
import { logoutAndRedirect } from './auth'
import { i18n } from "../config";
export function startChangePasswordFlow() {
    return {
        type: CHANGE_PASSWORD_INI,
    };
}


export function changePasswordRequest() {
    const message = i18n.t('common:text.change_password_api_message_wip');

    return {
        type: CHANGE_PASSWORD_REQUEST,
        payload: {
            message,
            statusType: "info",
        },
    };
}

export function receiveChangePassword(data) {
    const message = i18n.t('common:text.change_password_api_message_ok'); 

    return {
        type: CHANGE_PASSWORD_OK,
        payload: {
            message,
            data,
            statusText: "Password changed",
            statusType: "info",
        },
    };
}

export function receiveChangePasswordKO(error) {
    const status_code = (error.status_code === undefined) ? "403" : error.status_code;
    const message = i18n.t('common:text.change_password_api_message_ko') + " [error " + status_code + "]";

    return {
        type: CHANGE_PASSWORD_KO,
        payload: {
            status: status_code,
            message,
        },
    };
}

export function changePassword(token, currentPassword, newPassword, redirect) {
    return (dispatch) => {
        dispatch(changePasswordRequest());
        data_update_api_resource(token, "user/password", {password: newPassword, current: currentPassword} )
            .then(parseJSON)
            .then(response => {
                if (response.status === "OK") {
                    dispatch(receiveChangePassword(response.result));
                    dispatchNewRoute(redirect);
                }
                else
                    dispatch(receiveChangePasswordKO(response));

            })
            .catch(error => {
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            });
    };
}
