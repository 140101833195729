import React from "react";
import { LoadingAnimation } from "./LoadingAnimation";
import { AdBlockDetect } from "./AdBlockDetect";
import { i18n } from "../config";
import { Grid, Box, Typography } from "@material-ui/core";

const LoadingView = (props) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <AdBlockDetect>
          <div>
            <Box>
              <Typography variant={"h5"}>
                {i18n.t('common:text.loading_view_adblock_message')}
              </Typography>
            </Box>
          </div>
        </AdBlockDetect>
        <LoadingAnimation />
      </Grid>
    </Grid>
  );
};

export default LoadingView;
