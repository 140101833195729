import React from "react";
import SignUp from "../SignUp";
import { i18n } from "../../config";

import { Paper, Typography } from "@material-ui/core"

export default class SignUpView extends React.Component {

  render() {
    return (
      <div className="signup-container">
        <div className="signup-form">
          <Paper
            className="login-paper"
          >
            <div className="login-paper-inner" style={{textAlign: "left"}}>
              <Typography variant="h4">
                {i18n.t('common:text.signup_view_title')}
              </Typography>
              <SignUp />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
