import { useState } from 'react';
import CookieAcceptDialog from "./CookieAcceptDialog";
import CookiePersonalizeDialog from "./CookiePersonalizeDialog";
import Cookies from "../../utils/cookies";

const CookieDialog = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const [showBanner, setShowBanner] = useState(!Cookies.hasAcceptedCookies());

  const acceptedCookiesMap = {
    consent: Cookies.acceptConsentCookies,
    preferences: Cookies.acceptPreferencesCookies,
    statistics: Cookies.acceptStatisticCookies,
    marketing: Cookies.acceptMarketingCookies,
  };

  const acceptCookies = (acceptedCookies) => {
    setShowBanner(false);
    if (acceptedCookies === "all") {
      Cookies.acceptAllCookies();
    } else if (typeof acceptedCookies === "object") {
      for (const [key, value] of Object.entries(acceptedCookies)) {
        if(value) {
          acceptedCookiesMap[key]();
        }
      }
    }
  };

  const personalizeCookies = () => {
    setShowPreferences(true);
  };

  return (
    <div className="wrapper" role="contentinfo">
      {showBanner && !showPreferences && <CookieAcceptDialog {...{acceptCookies, personalizeCookies}}/>}
      {showBanner && showPreferences && <CookiePersonalizeDialog {...{acceptCookies, personalizeCookies}}/>}
    </div>
  );
}

export default CookieDialog;