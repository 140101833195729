import React from 'react';
import { connect } from 'react-redux';
import { i18n } from "../config";

import ContractsView from './ContractsView';
import ContractsViewGas from './ContractsViewGas';
import { features } from "../settings";

import { Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';
import GasIcon from '../assets/GasIcon';
import LightIcon from '../assets/LightIcon';
import { LoadingAnimation } from './LoadingAnimation';

function mapStateToProps(state) {
    const contracts = state.contracts;
    const contractsGas = state.contractsGas;
    return {
        total_items: contracts.total_items,
        total_items_gas: contractsGas.total_items,
        loading_contracts: contracts.isFetching || contractsGas.isFetching
    };
}

class ContractsTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedElectricity: true,
            selectedGas: false,
            value: "electricity",
        };
    }

    handleChange = (event, value) => {
        this.setState({
            selectedElectricity: value === 'electricity' ? true : false,
            selectedGas: value === 'gas' ? true : false,
            value: value,
        });
    };
    

    render() {

        let tabComponent;
        let showEmptyTabs = true;
        let empty = new Set([0, undefined])

        if (!features.showEmptyContractsInvoices){
            showEmptyTabs = !(empty.has(this.props.total_items) || empty.has(this.props.total_items_gas));
        }

        if (features.newElectricityContract && features.newGasContract && showEmptyTabs){
            tabComponent = (
                this.props.loading_contracts ?
                    <LoadingAnimation />
                    :
                    <TabContext value={this.state.value}>
                        <TabList className="tabs" onChange={this.handleChange}>
                            <Tab
                                label={i18n.t('common:text.contracts_tab_title_electricty')}
                                icon={<LightIcon className="light-icon"/>}
                                className={"tab tab-electricity selected-tab-" + this.state.selectedElectricity}
                                value="electricity"
                            />
                            <Tab
                                label={i18n.t('common:text.contracts_tab_title_gas')}
                                icon={<GasIcon className="gas-icon"/>}
                                className={"tab tab-gas selected-tab-" + this.state.selectedGas}
                                value="gas"
                            />
                        </TabList>
                        <TabPanel value={"electricity"}>
                            <ContractsView {...this.props}/>
                        </TabPanel>
                        <TabPanel value={"gas"}>
                            <ContractsViewGas {...this.props}/>
                        </TabPanel>
                    </TabContext>
            );
        } else if(features.newGasContract) {
            tabComponent = (<ContractsViewGas {...this.props}/>);
        } else if(features.newElectricityContract){
            tabComponent = (<ContractsView {...this.props}/>);
        } else {
            tabComponent = (<div>Please configure a contract type.</div>);
        }

        return(tabComponent);
    }
}

export default connect(mapStateToProps)(ContractsTabs);