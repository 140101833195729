import _ from "lodash";
import {
    RECEIVE_INVOICES_MULTIPOINT,
    RECEIVE_INVOICES_MULTIPOINT_FILTER,
    FETCH_INVOICES_MULTIPOINT_REQUEST,
    FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST,
    EXPORT_INVOICE_MULTIPOINT_REQUEST,
    EXPORT_INVOICE_MULTIPOINT_DONE,
    MESSAGE_INVOICE_MULTIPOINT
} from '../constants';
import { createReducer } from '../utils/misc';
import Settings from "../settings";

const initialState = {
    isFetching: false,
    loaded: false,
    download_status: {},
    invoice_function: null,
    page_items: _.get(Settings, "invoices.itemsPerPage", 100),
    filters: {
        loading: false,
        data: {},
        currentFilter: ''
        // cache: {}
    },
    errors: null
};

export default createReducer(initialState, {
    [RECEIVE_INVOICES_MULTIPOINT]: (state, payload) => {
        // const newItems = _.union(state.filters.cache[payload.filters].items, payload.response.items);
        return Object.assign({}, state, {
            items: payload.response.items,
            total_items: payload.response.n_items,
            offset_items: payload.response.offset,
            page_items: payload.response.limit,
            isFetching: false,
            loaded: true,
            message_text: payload.message,
            errors: payload.errors,
            filters: {
                ...state.filters,
                currentFilter: payload.filters
                // cache: {
                //     ...state.filters.cache,
                //     [payload.filters]: {
                //         items: newItems
                //     },
                //     n_items: payload.n_items,
                //     recieved: newItems.length
                // }
            }
        })
    },
    [RECEIVE_INVOICES_MULTIPOINT_FILTER]: (state, payload) =>
        Object.assign({}, state, {
            filters: {
                ...state.filters,
                loading: false,
                data: payload
            }
        }),
    [FETCH_INVOICES_MULTIPOINT_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            isFetching: true,
            loaded: false,
            message_text: payload.message,
        }),
    [FETCH_INVOICES_MULTIPOINT_FILTER_REQUEST]: (state, payload) =>
        Object.assign({}, state, {
            filters: {
                ...state.filters,
                loading: true,
                data: {}
            }
        }),
    [EXPORT_INVOICE_MULTIPOINT_REQUEST]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = true;
        return Object.assign({}, state, {
            download_status,
            message_text: payload.message
        });
    },
    [EXPORT_INVOICE_MULTIPOINT_DONE]: (state, payload) => {
        const download_status = Object.assign({}, state.download_status);
        download_status[payload.invoice] = false;
        return Object.assign({}, state, {
            download_status
        });
    },
    [MESSAGE_INVOICE_MULTIPOINT]: (state, payload) => {
      return Object.assign({}, state, {
            message_text: payload.message,
        })
    },
    ALT_INVOICE_MULTIPOINT_FUNCTION: (state, payload) =>
        Object.assign({}, state, {
            invoice_function: payload
        }),
});
