import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contractActionCreators from '../../actions/contractsGas';
import * as authActionCreators from '../../actions/auth';
import { i18n } from "../../config";
import _ from 'lodash';
import { LoadingAnimation } from '../LoadingAnimation';
import { debug } from '../../utils/debug';
import { Typography, Box } from '@material-ui/core';

import AltGasContract from '../AltGasContract';

function mapStateToProps(state) {
    const contracts = state.contractsGas;
    return {
        data: contracts,
        contract: contracts.view_contract,
        token: state.auth.token,
        loaded: contracts.loaded,
        isFetching: contracts.isFetching,
        error: contracts.error,
        errorMessage: contracts.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            contract: bindActionCreators(contractActionCreators, dispatch),
            auth: bindActionCreators(authActionCreators, dispatch),
        }
    } 
}

class ContractGas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            no_contract: false
        };
    }

    componentDidMount() { 
        if (_.isEmpty(this.props.data.items)) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.loaded && !prevProps.loaded) {
            const contract = this.props.contract ?
                this.props.contract :
                this.props.data.items[0];
            contract ?
                this.props.actions.contract.viewContract(contract)
            :
                this.setState({no_contract: true})

        }
    }

    fetchData(initial=true, offset=0, filter=null) {
        const token = this.props.token;
        this.props.actions.contract.fetchContracts(token, true, initial, offset, filter);
    }

    render() {
        return (
            <div>
                {
                this.state.no_contract?
                (
                    <Typography variant={'h6'}>
                        {i18n.t('common:text.contact_view_no_contracts')}
                    </Typography>
                )
                :
                    (this.props.loaded && this.props.contract)?
                        <div>
                            <Box mb={2}>
                                <Typography variant={'h4'}>
                                    {i18n.t('common:text.contract_title')}
                                </Typography>
                            </Box>
                            <AltGasContract/>    
                            {debug(this.props.data)}
                        </div>
                    :
                        <div>
                            <LoadingAnimation />
                        </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractGas);

ContractGas.propTypes = {
};
