import _ from "lodash";
import React from "react";
import validate from "validate.js";
import CUPSField, { cupsValid } from "../CUPSField";
import AreaField from "../AreaField";
import TextFieldReadOnly from "../TextFieldReadOnly";
import Appliance from "./Appliance";
import {APPLIANCES, ownIcons} from "./custom/config";
import {
  data_create_api_resource,
  dispatchNewRoute
} from "../../utils/http_functions";
import LeadContact from "../LeadContact";
import { i18n } from "../../config";
import Settings, {organization} from "../../settings";
import HelpPanel from "../HelpPanel";
import Cookies from '../../utils/cookies';
import { marketingHook } from "../../overrides/hooks/power-calculator";

import { RadioGroup, FormControlLabel, Radio, Button, TextField, 
  Box, Typography, Grid, FormHelperText } from '@material-ui/core';


class CalculadoraPotencia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area: 70,
      numPersones: 1,
      cups: '',
      appliances: [],
      cofSimul: 0.4,
      showResult: false,
      leadSent: false,
    };
    validate.validators.presence.options = {
      message: i18n.t('common:text.required_field'),
    };
    validate.validators.cupsPotencia = (value, options, key, attributes) => {
      if (value && !cupsValid(value)) {
        return i18n.t('common:text.cups_field_not_valid');
      } else {
        return null;
      }
    };

    this.validations = {
      cups: {
        presence: { allowEmpty: false, message: "^" + i18n.t('common:text.lead_power_cups_validation_required') },
        cupsPotencia: true,
      }
    };
  }

  validate = (attrs = null, validations) => {
    let onlyValidate = {};
    const _validations = validations || this.validations;
    if (attrs) {
      // eslint-disable-next-line no-return-assign
      Object.keys(attrs).forEach((key) => (onlyValidate[key] = _validations[key]));
    } else {
      onlyValidate = Object.assign({}, _validations);
    }
    const errors = validate(attrs || this.state, onlyValidate);
    this.setState({ errors });
    return errors;
  };

  addAppliance = (appliance) => {
    const appliances = [...this.state.appliances.filter(
      v => v.name !== appliance.name
    ), appliance];
    this.setState({appliances})
  }

  removeAppliance = (appliance) => {
    const appliances = this.state.appliances.filter(
      v => v.name !== appliance.name
    );
    this.setState({appliances});
  }

  handleChangeAppliance = (appliance) => {
    if (appliance.enabled) {
      this.addAppliance(appliance);
    } else {
      this.removeAppliance(appliance);
    }
  }

  showResult = () => {
    const errors = this.validate(this.state);
    if (_.isEmpty(errors)) {
      this.sendLead();
      this.setState({ showResult: true });
    }
  }

  leadSubmit = async (data) => {
    await this.setState({...data});
    const response = await this.sendLead();
    if (response.status === 200) {
      this.setState({leadSent: true})
    }
  }

  sendLead = async () => {
    const values = Object.assign({}, this.state);
    console.log(values);
    values.optimalPower = Math.round(this.state.appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100;
    const response = await data_create_api_resource(null, 'leads/potencia', values);
    console.log(response);
    return response;
  }

  handleHelpPanelClick = () => {
    if(i18n.exists('common:url.calculatorHelp')){
      dispatchNewRoute(i18n.t('common:url.calculatorHelp'));
    }
  };

  componentDidMount() {
    if (Cookies.marketingConsentGiven()) {
      marketingHook();
    }
  }

  render() {
    const maxPower = Math.round(this.state.appliances.reduce((x, y) => x + (y.totalPower), 0) * 100) / 100;
    const aclarimentTemplate = _.template(i18n.t('common:text.lead_power_aclariment'))
    const { errors } = this.state;
    return (
      <div className="power-calculator">
        {!this.state.showResult && (
          <div>
            <Box>
              <Typography variant="h5">{i18n.t('common:text.lead_power_title')}</Typography>
            </Box>

            <Grid container spacing={3} justifycontent={'center'}>
              <Grid item xs={12} md={6} lg={4}>
                <CUPSField
                  label="CUPS*"
                  errorText={errors && errors.cups}
                  onChange={(event, value) => this.setState({ cups: value })}
                  style={{ width: 300 }}
                />
                <div className="helper-text" style={{ width: 300 }}>
                  {i18n.t('common:text.contractation_cups_helper')}
                </div>
                <div>
                  <AreaField
                    label={i18n.t('common:text.lead_power_area')}
                    value={this.state.area}
                    onValueChange={({ value: v }) => this.setState({ area: v })}
                    decimalScale={0}
                    style={{ width: 300 }}
                    error={!!(errors && errors.area)}
                  />
                  {errors && errors.area && (
                    <FormHelperText error={true}>{errors.area}</FormHelperText>
                  )}
                </div>
                <div>
                  <TextField
                    label={i18n.t('common:text.lead_power_people')}
                    type={"number"}
                    min={0}
                    value={this.state.numPersones}
                    style={{ width: 300 }}
                    onChange={(event) =>
                      this.setState({ numPersones: event.target.value })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextFieldReadOnly label={i18n.t('common:text.lead_power_cofsimul')}>
                  <RadioGroup
                    name="shipSpeed"
                    defaultValue={0.4}
                    onChange={(event) =>
                      this.setState({ cofSimul: Number(event.target.value) })
                    }
                    value={this.state.cofSimul}
                  >
                    <FormControlLabel
                      value={0.25}
                      control={<Radio />}
                      label={i18n.t('common:text.lead_power_cofsimul_pocs')}
                    />
                    <FormControlLabel
                      value={0.4}
                      control={<Radio />}
                      label={i18n.t('common:text.lead_power_cofsimul_alguns')}
                    />
                    <FormControlLabel
                      value={0.6}
                      control={<Radio />}
                      label={i18n.t('common:text.lead_power_cofsimul_molts')}
                    />
                  </RadioGroup>
                </TextFieldReadOnly>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                {_.get(Settings, "powerCalculator.sideContact") && (
                  <HelpPanel onClick={this.handleHelpPanelClick} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h5">
                    {i18n.t('common:text.lead_power_appliances')}
                  </Typography>
                </Box>
                <TextFieldReadOnly label={i18n.t('common:text.lead_power_appliances_question')}>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontSize: 12,
                      color: "rgba(0, 0, 0, 0.3)",
                      marginLeft: 10,
                    }}
                  >
                    {i18n.t('common:text.lead_power_appliances_question_hint')}
                  </div>
                </TextFieldReadOnly>
                <div className="gridList">
                  {APPLIANCES.map((appliance, index) => (
                    <Appliance
                      key={index}
                      {...appliance}
                      cofSimul={this.state.cofSimul}
                      area={this.state.area}
                      onChange={this.handleChangeAppliance}
                    />
                  ))}
                </div>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  className="fancy-secondary-btn"
                  onClick={this.showResult}
                >
                  {i18n.t('common:text.lead_power_calculate')}
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {this.state.showResult && (
          <div>
            {!this.state.leadSent && (
              <div className="result">
                {!this.state.helpRequested && (
                  <div>
                    <Typography variant="h4" align="center">
                      {i18n.t('common:text.lead_power_optimal')}
                    </Typography>
                    <div className="value">
                      {maxPower.toString().replace(".", ",")} kW
                    </div>
                    <div>
                      <Typography variant="body2" align="center">{i18n.t('common:text.lead_power_text1')}</Typography>
                      <ul>
                        <li>
                          <Typography variant="body2">
                            {i18n.t('common:text.lead_power_text2')}
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            {i18n.t('common:text.lead_power_text3')}
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2">
                            {i18n.t('common:text.lead_power_text4')}
                          </Typography>
                        </li>
                      </ul>
                      <p>
                        {i18n.t('common:text.lead_power_essential')}
                      </p>
                      <p>
                        {i18n.t('common:text.lead_power_aclariment_canvi_potencia')}
                      </p>
                      <p>
                        {aclarimentTemplate({
                          companyName: organization.name,
                        })}
                      </p>
                    </div>
                  </div>
                )}
                <LeadContact
                  changePowerHelp={true}
                  onSubmit={(data) => this.leadSubmit(data)}
                />
              </div>
            )}
            {this.state.leadSent && (
              <Box>
                <Typography variant="h4">
                  Moltes gràcies, ens posarem en contacte amb tu.
                </Typography>
              </Box>
            )}
          </div>
        )}
        {!ownIcons && (
          <footer style={{ marginTop: 100 }}>
            <p>
              Icones creats per
              <a href="https://www.flaticon.com/authors/pause08">pause08</a>,
              <a href="https://www.flaticon.com/authors/smashicons">
                Smashicons
              </a>
              ,<a href="https://www.flaticon.com/authors/monkik">monkik</a>,
              <a href="https://www.flaticon.com/authors/freepik">Freepik</a>,
              <a href="https://www.flaticon.com/authors/photo3idea-studio">
                photo3idea_studio
              </a>
              ,
              <a href="https://www.flaticon.com/authors/ultimatearm">
                ultimatearm
              </a>
              ,<a href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>,
              de <a href="http://www.flaticon.com">www.flaticon.com</a>
            </p>
          </footer>
        )}
      </div>
    );
  }
}

export default CalculadoraPotencia;
