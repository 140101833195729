import React from 'react';
import { connect } from 'react-redux';

import InvoicesView from './Invoices/InvoicesView';
import InvoicesViewGas from './InvoicesViewGas';
import InvoicesMultipointView from "./InvoicesMultipoint/InvoicesMultipointView";

import { features } from "../settings";
import { Tab } from '@material-ui/core';
import { TabPanel, TabContext, TabList } from '@material-ui/lab';

import GasIcon from '../assets/GasIcon';
import LightIcon from '../assets/LightIcon';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import { i18n } from "../config";

function mapStateToProps(state) {
    const invoices = state.invoices;
    const invoicesGas = state.invoicesGas;
    return {
        total_items: invoices.total_items,
        total_items_gas: invoicesGas.total_items,
        loading_invoices: invoices.isFetching || invoicesGas.isFetching,
        settings: state.settings,
    };
}

class InvoicesViewTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedElectricity: true,
            selectedGas: false,
            selectedMultipoint: false,
            value: "electricity",
        };
    }

    componentDidUpdate(prevProps, prevState) {
      if(this.props.settings?.data?.showInvoicesMultipunt !== prevProps.settings?.data?.showInvoicesMultipunt){
        if (this.props.settings?.data?.showInvoicesMultipunt){
          this.setState({
            selectedElectricity: false,
            selectedMultipoint: true,
            value: "multipoint",
          });
        }
      }
    }

    handleChange = (event, value) => {
        this.setState({
            selectedElectricity: value === 'electricity' ? true : false,
            selectedGas: value === 'gas' ? true : false,
            selectedMultipoint: value === 'multipoint' ? true : false,
            value: value,
        });
      };

    render() {
        let tabComponent;
        let showEmptyTabs = true;
        let empty = new Set([0, undefined])

        if (!features.showEmptyContractsInvoices){
            showEmptyTabs = !(empty.has(this.props.total_items) || empty.has(this.props.total_items_gas));
        }

        let showInvoicesMultipunt = this.props.settings?.data?.showInvoicesMultipunt;
        let invoicesMultipointTab = <></>;
        if (showInvoicesMultipunt) {
          invoicesMultipointTab = (
            <Tab
              label={i18n.t("common:text.contracts_tab_title_multipoint")}
              icon={<DeviceHubIcon />}
              className={"tab tab-multipoint selected-tab-" + this.state.selectedMultipoint}
              value="multipoint"
            />
          )
        }

        let electricityTab = (
          <Tab
            label={i18n.t("common:text.contracts_tab_title_electricty")}
            icon={<LightIcon />}
            className={"tab tab-electricity selected-tab-" + this.state.selectedElectricity}
            value="electricity"
          />
        )

        let gasTab = (
          <Tab
            label={i18n.t("common:text.contracts_tab_title_gas")}
            icon={<GasIcon />}
            className={"tab tab-gas selected-tab-" + this.state.selectedGas}
            value="gas"
          />
        )

        const renderTabComponent = (tabs, panels) => {
          return (
            <TabContext value={this.state.value}>
              <TabList
                className="tabs"
                onChange={this.handleChange}
                scrollButtons="on"
                variant="scrollable"
              >
                {tabs}
              </TabList>
              {panels}
            </TabContext>
          );
        };

        const renderInvoicesView = (type, isTabbed) => {
          return (
            <TabPanel value={type}>
              {
                type === 'electricity' ?
                  <InvoicesView isTabbed={isTabbed} {...this.props} /> :
                type === 'gas' ?
                  <InvoicesViewGas isTabbed={isTabbed} {...this.props} /> :
                type === 'multipoint' ?
                <InvoicesMultipointView isTabbed={true} {...this.props}/>:
                <></>
              }
            </TabPanel>
          );
        };


        if (features.newElectricityContract && features.newGasContract && showEmptyTabs) {
          const tabs = [];
          const panels = [];

          if (showInvoicesMultipunt) tabs.push(invoicesMultipointTab);
          if (features.newElectricityContract) tabs.push(electricityTab);
          if (features.newGasContract) tabs.push(gasTab);

          tabs.forEach(tab => panels.push(renderInvoicesView(tab.props.value, true)));

          tabComponent = renderTabComponent(tabs, panels);
        } else if (features.newGasContract && this.props.showGas) {
          const tabs = [];
          const panels = [renderInvoicesView('gas', true)];

          if (showInvoicesMultipunt) {
            tabs.push(invoicesMultipointTab);
            panels.push(renderInvoicesView('multipoint', true));
            tabs.push(gasTab);
            tabComponent = renderTabComponent(tabs, panels);
          }
          else {
            tabComponent = <InvoicesViewGas isTabbed={false} {...this.props} />
          }
        } else if (features.newElectricityContract) {
          const tabs = [];
          const panels = [renderInvoicesView('electricity', true)];

          if (showInvoicesMultipunt) {
            tabs.push(invoicesMultipointTab);
            panels.push(renderInvoicesView('multipoint', true));
            tabs.push(electricityTab);
            tabComponent = renderTabComponent(tabs, panels);
          }
          else {
            tabComponent = <InvoicesView isTabbed={false} {...this.props} />
          }
        } else {
          tabComponent = <div>Please configure a contract type.</div>;
        }

        return tabComponent;

    }
}

export default connect(mapStateToProps)(InvoicesViewTabs);