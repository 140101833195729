import React, { useState, useEffect } from 'react';
import { checkVAT, spain } from "jsvat";

import { useTranslation } from 'react-i18next';
import { TextField, FormHelperText, Box } from "@material-ui/core";

export const validateVAT = (value) => {
  if (!value) {
    return false
  }
  let revised_value = value.startsWith("ES") ? value : "ES" + value
  const result = checkVAT(revised_value, [spain]);
  return result.isValid;
};

export const isCompanyVAT = (value) => {
    let company = false;
    if (value) {
      if (value.length > 1) {
        company = "ABCDEFGHJNPQRSUVW".indexOf(value[0]) >= 0 && !isNaN(value[1]);
      }
    }
    return company;
};

const VATField = ({label, name, value, onChange, showError, error, disabled, style}) => {
  const { i18n } = useTranslation();
  const [displayVAT, setDisplayVAT] = useState(value);

  const handleChange = (event, format=false) => {
    let vat = event.target.value.toUpperCase();

    if (format) {
      vat = vat ? vat.replace('ES', '') : ''
    }
    setDisplayVAT(vat);

    if (onChange) {
      let newEvent = {...event}
      newEvent.target.value = vat
      onChange(newEvent);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
      }}
    >
      <TextField
        label={label || i18n.t('common:text.vat_field_default_label')}
        name={name}
        onChange={handleChange}
        onBlur={(event) => handleChange(event, true)}
        value={displayVAT}
        error={!!error && showError}
        disabled={disabled}
        style={style}
      />
      {error && showError && (
        <FormHelperText error={true}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default VATField;
