import { Box, Paper, Typography } from "@material-ui/core";
import useBatteryDistributionPreview from "./useBatteryDistributionPreview";
import {
  BatteryDistributionAfter,
  BatteryDistributionBefore,
} from "./BatteryDistributionCard";
import { i18n } from "../../../config";
import { useSelector } from "react-redux";

export const BatteryDistributionPreview = ({ currentBatteryDistribution, newPercentage, selectedBatteryId, expectedAction="move" }) => {
  const { items, view_contract } = useSelector(
    (state) => state.contracts
  );
  const { getExpectedDistribution } = useBatteryDistributionPreview();

  let distributionParams = {
    contracts: items,
    newPercentage,
    forceCurrentName: view_contract.name
  };


  if (expectedAction === "changePercentage") {
    distributionParams["expectedAction"] = "changePercentage";
    distributionParams["contractUpdated"] = view_contract.id;
    distributionParams["editedBatteryId"] = selectedBatteryId;
  } else {
    distributionParams["movedBatteryId"] = selectedBatteryId;
    distributionParams["expectedAction"] = "move";

  }
  const newExpectedDistribution = getExpectedDistribution(distributionParams);

  return (
    <Box>
      <Paper
        style={{
          padding: "1rem",
          overflowY: "auto",
          maxHeight: "35vh",
        }}
        variant="outlined"
      >
        {/* <Typography variant="subtitle2"> */}
        {/**/}
        {/*   {i18n.t("common:text.altHome_virtual_battery")}: {currentBatteryDistribution["batteryName"]} */}
        {/* </Typography> */}
        <Box style={{ position: "relative" }}>
          <BatteryDistributionAfter batteryDistribution={newExpectedDistribution} />
          {/* <hr /> */}
          {/* <BatteryDistributionBefore batteryDistribution={currentBatteryDistribution} /> */}
        </Box>
      </Paper>
    </Box>
  );
};

export default BatteryDistributionPreview;
