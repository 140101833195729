import React from "react";
import marked from "marked";
import { executeHooks } from "../../utils/hooks";

export default class RichContent extends React.Component {
  componentDidMount() {
    const { html, content } = this.props;
    if (html && content) {
      executeHooks(content);
    }
  }

  render() {
    const { content, html, inline, ...rest } = this.props;
    let markup = "";
    if (html) {
      markup = content;
    } else {
      if (inline) {
        markup = marked.parseInline(content);
        return <span dangerouslySetInnerHTML={{ __html: markup }} />;
      } else {
        markup = marked(content);
      }
    }
    
    return <div {...rest} dangerouslySetInnerHTML={{ __html: markup }} />;
  }
}
