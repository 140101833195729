import _ from "lodash";
import React from "react";
import {Helmet} from "react-helmet";

/* theme creation */
import { i18n } from "../../config";
import Settings, {StyleVars} from "../../settings";
import "../../settings/validators";
import HelpDialog from "../../components/HelpDialog";
import { CouponsView } from "../../components/CouponsView";

/* Application Headers */
import Header from "../../components/Header";
import HeaderHorizontal from "../../components/HeaderHorizontal";

import { Paper } from "@material-ui/core";

import { horizontalHeaderColorStyle } from "../../overrides/hooks/styles";

const headerVariant = _.get(Settings, "header.variant", "vertical");

const App = (props) => {

  let paper = false;
  switch (props.location.pathname) {
    // Container without envelope
    case i18n.t('common:url.login'):
    case i18n.t('common:url.altHome'):
    case i18n.t('common:url.connect'):
    case i18n.t('common:url.activate'):
      paper = false;
      break;
    // Container with an envelope based on a paper
    default:
      paper = true;
  }

  let coupons = false;
  switch (props.location.pathname) {
    // Container without envelope
    case i18n.t('common:url.contracts'):
    case i18n.t('common:url.invoices'):
      coupons = true;
      break;
    // Container with an envelope based on a paper
    default:
      coupons = false;
  }
  
  const [dimensions, setDimensions] = React.useState({ 
    width: window.innerWidth
  })
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)
    /*
      Si aquest component es torna a renderitzar sovint, això podria crear una fuita de memòria greu al nostre programa, 
      per això cal realitzar qualsevol neteja necessària.
    */
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const propHorizontalHeaderColorStyle = horizontalHeaderColorStyle ? horizontalHeaderColorStyle() : "default";

  return (
    <section>
      <Helmet>
        <title>{i18n.t('common:text.title')}</title>
      </Helmet>
      {/* StyleVars.desktop not working, fix later. Temporal solution is to hardcode '992' */}
      {headerVariant === "horizontal" && (dimensions.width > 992) ? (
        <HeaderHorizontal color={propHorizontalHeaderColorStyle}/>
      ) : (
        <Header />
      )}

      {
        coupons && <CouponsView />
      }
      {paper && (
        <Paper
          className="container paper-container"
          elevation={5}
        >
          {props.children}
        </Paper>
      )}
      {!paper && props.children}
      { _.get(Settings, "helpDialog.enabled", false) && (
        <HelpDialog />
      )}
    </section>
  );
};

export { App };
