import React from "react";
import { i18n } from "../../config";

import { CircularProgress, Box, Typography } from "@material-ui/core";

const styles = {
  page: {
    textAlign: "center",
    width: "100%",
  },
};

export const LoadingAnimation = () => {
  return (
    <div style={styles.page}>
      <Box>
        <Typography variant={"h5"}>
          {i18n.t('common:text.loading')}
        </Typography>
      </Box>
      <br />
      <CircularProgress size={80} thickness={3} />
    </div>
  );
};
