import React from "react";
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';


const EurosField = (props) => {
  return (
    <NumberFormat
        style={{width: '100%', maxWidth: '300px'}}
        customInput={TextField}
        suffix=" €"
        type="text"
        allowedDecimalSeparators={[',', '.']}
        decimalSeparator=","
        thousandSeparator=" "
        isNumericString={true}
        {...props}
      />
  )
}

export default EurosField;