import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";

import Routes from './routes';
import './style/index.scss';

import Cookies from './utils/cookies';
import CookieDialog from './components/CookieDialog';

import { ThemeProvider } from '@material-ui/core/styles';

import { i18n, theme, store, history } from './config';

import { initSentry } from './sentry'; 
import SettingsProvider from './components/SettingsProvider';

initSentry();

const currentLang = Cookies.getLang(); // INFO: Is the lang from cookies, token, browse or DEFAULT_LANGUAGE
if (currentLang) {
    Cookies.saveLang(currentLang); // INFO: Force the set to verify the language(Refactor required, create the set language func and use it everywhere!)
    i18n.changeLanguage(Cookies.getLang());
}

history.listen( location => {
    Cookies.updateMarketingScripts(location);
    Cookies.updateStatisticScripts(location);
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <SettingsProvider>
                    <Router history={history}>
                        <Routes/>
                    </Router>
                </SettingsProvider>
                <CookieDialog/>
            </I18nextProvider>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);
