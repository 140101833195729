import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contractActionCreators from '../../actions/contracts';
import ErrorMessage from "../ErrorMessage";
import { CircularProgress, Button,
          Dialog, DialogTitle, DialogContent, TextField, FormHelperText, DialogActions, Typography, Slider } from '@material-ui/core';
import { Create,GetApp } from '@material-ui/icons';
import { i18n } from "../../config";
import * as virtualBatteryCreators from "../../actions/bateriaVirtual";

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        contract: bindActionCreators(contractActionCreators, dispatch),
        virtualBattery: bindActionCreators(virtualBatteryCreators, dispatch),
      }
    }
  }
  
const style = {
    button: {
        margin: "20px",
    },
};

const STATUS = {
  INIT: 'init',
  SENDING: 'sending',
  SENT: 'sent',
  ERROR: 'error'
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  contract: state.contracts
});


class VirtualBatteryDownload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
    }
  }

  handleSubmit = async () => {
    const {bateries, token} = this.props;

    let battery_ids = ""
    for (let n = 0; n < bateries.length; n++) {
        battery_ids = battery_ids + bateries[n].bateria.id
        if (n < bateries.length -1){
          battery_ids = battery_ids + ","
        }
    }

    let error = false;
    try {
      this.props.actions.virtualBattery.exportVirtualBatteries(token, battery_ids)
    } catch (e) {
      console.log(e)
      error = true;
    } finally {
      this.setState({
        error,
      })
    }
  };

  render() {
    const { error, sending } = this.state;

    return (
      <div style={style.button}>
        <span>
          <Button 
            variant={'contained'}
            color={'primary'} 
            onClick={this.handleSubmit}
            disabled={sending}>

            {sending ? <CircularProgress style={{ marginRight: 10 }} size={25} /> : <GetApp style={{marginRight: 10}}/>}
            { i18n.t('common:text.virtual_battery_download_excel') } 
          </Button>
        </span>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualBatteryDownload);
