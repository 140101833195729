import _ from "lodash";
import React from 'react';
import UploadButton from "./UploadButton";
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { i18n } from "../config";
import Settings from "../settings";

const FILE_SIZE_LIMIT = Settings.fileUploadSizeLimit ? Settings.fileUploadSizeLimit : 500000000;

export const validate = (value, options) => {
  const object = options.object || i18n.t('common:text.document');
  const minUploads = options.min || 0;
  const maxUploads = options.max || 0;
  const isIterable = Symbol.iterator in Object(value);
  const minMessage = options.minMessage || i18n.t('common:text.multiupload_validation_min');
  const maxMessage = options.maxMessage || i18n.t('common:text.multiupload_validation_max');

  if (minUploads > 0) {
    if (!isIterable) {
      return _.template(minMessage)({object, count: minUploads });
    } else {
      const notNulls = value.filter((x) => x !== null && x !== undefined);
      if (notNulls.length < minUploads) {
        return _.template(minMessage)({object, count: minUploads });
      }
      if (maxUploads > 0 && notNulls.length > maxUploads) {
        return _.template(maxMessage)({object, count: maxUploads });
      }
    }
  }
};


class MultiUploadButton extends React.Component {
  constructor(props) {
    super(props);
    const uploadsArr =  props.value || [...Array(props.min)];
    this.state = {
      uploadsArr,
      error: {0: null}
    }
  }

  hasUploadErrors = () => {
    let errors = this.state.error;
    let trobat = false;
    Object.keys(errors).forEach(key => {
      if (errors[key] !== null) {
        trobat = true;
      }
    });
    return trobat;
  }

  manageOnChange = () => {
    const trimmedUploadArr = this.state.uploadsArr.filter((value) => {
      return !_.isEmpty(value);
    });
    let upErr = this.hasUploadErrors()
    this.props.onChange(trimmedUploadArr, upErr);
  }

  handleOnChange = (position, value, size) => {
    this.setState({error: {...this.state.error, [position]: null}}, () => {
      if (size <= FILE_SIZE_LIMIT) {
        const uploadsArr = [...this.state.uploadsArr];
        uploadsArr[position] = value;
        this.setState({uploadsArr}, () => this.manageOnChange());
      } else if (size !== undefined) {
        this.setState({error: {...this.state.error, [position]: i18n.t('common:text.file_too_large', {amount: FILE_SIZE_LIMIT/1000000}) }}, () => this.manageOnChange());
      } else {
        const uploadsArr = [...this.state.uploadsArr];
        uploadsArr[position] = undefined;
        this.setState({uploadsArr}, () => this.manageOnChange());
      }
    });
  }
  

  addAnotherUploadArr = (e) => {
    e.preventDefault();
    const uploadsArr = [...this.state.uploadsArr];
    this.setState({error: {...this.state.error, [this.state.uploadsArr.length]: null}});
    uploadsArr.push(undefined);
    this.setState({uploadsArr});
  }

  removeUpload = (e) => {
    e.preventDefault();
    const uploadsArr = [...this.state.uploadsArr];
    const value = uploadsArr.pop();
    this.setState({uploadsArr});
    if (value) {
      this.props.onChange(uploadsArr);
    }
  }

  render() {
    const uploadButtonsArr = this.state.uploadsArr.map((k, i) => {
      let label;
      if (this.state.uploadsArr.length > 1) {
        if(i > 0) {
          label = `${this.props.anotherLabel} - ${i + 1}`;
        } else {
          label = `${this.props.label} - ${i + 1}`;
        }
      } else {
        label = this.props.label;
      }
      const error1 = (i < this.props.min) ? this.props.errorText : '';
      return (
        <div key={i} style={{display: 'flex'}}>
          <UploadButton 
            label={label} 
            errorText={(this.state.error != {} && this.state.error[i] !== null) ? this.state.error[i] : error1} 
            onChange={(value, size) => this.handleOnChange(i, value, size)} 
            value={k}
            accept={this.props.accept}
          />
          {i + 1 > this.props.min && i + 1 ===  this.state.uploadsArr.length &&
            <Button
              variant={'text'}
              onClick={(event) => {
                this.removeUpload(event)} 
              }
              label={this.props.removeLabel} 
            >
              {<RemoveIcon/>}
              { 
                <div style={{fontSize: 12}}>
                  {this.props.removeLabel}
                </div>
              }
            </Button>
          }
        </div>
      )
    });
    const allowMoreUplodads = this.state.uploadsArr.length < this.props.max;
    return (
      <div>
        {uploadButtonsArr}
        {allowMoreUplodads &&
          <Button
            variant={'text'}
            onClick={this.addAnotherUploadArr}
          >
            {<AddIcon/>} 
            <div style={{fontSize: 12}}>
              {this.props.anotherLabel} 
            </div>
          </Button>
        }
        {this.props.hint &&
          <div className="helper-text">{this.props.hint}</div>
        }
      </div>
    )
  }
}

export default MultiUploadButton;


MultiUploadButton.defaultProps = {
  min: 1,
  max: 5,
  value: [],
  anotherLabel: 'Add',
  removeLabel: 'Remove'
};
