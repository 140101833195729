import React from "react";
import { TextField } from '@material-ui/core';
import NumberFormat from "react-number-format";

const EnergyConsumptionField = (props) => {
  return (
    <NumberFormat
      customInput={TextField}
      suffix=" kWh"
      type="text"
      allowedDecimalSeparators={[",", "."]}
      decimalSeparator=","
      thousandSeparator=" "
      isNumericString={true}
      {...props}
    />
  );
};

export default EnergyConsumptionField;
