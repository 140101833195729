import {
    FETCH_CONTACT_REQUEST,
    RECEIVE_CONTACT,
    RECEIVE_CONTACT_ERROR,
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_OK,
    CREATE_CONTACT_KO,
    SET_CONTRACT_ITEMS,
} from '../constants/index'

import {
    logoutAndRedirect
} from './auth';
import {
    data_fetch_api_resource,
    data_create_api_resource,
    data_delete_api_resource,
} from '../utils/http_functions'

import {
    parseJSON
} from '../utils/misc'


export function setContractItems(newItemsList) {
    return {
        type: SET_CONTRACT_ITEMS, 
        payload: newItemsList
    };
}
export function syncBateriaVirtuals(bateriesVirtualsMap) {
    return (dispatch, getState) => {
        const state = getState();
        let syncedItems = [...state.contracts.items];
        for (let contract of syncedItems) {
            for (let bateriaVirtual of (contract.bateria_virtual ?? [])) {
                const updatedBV = bateriesVirtualsMap.find((b) => b.bateria_id === bateriaVirtual.id);
                if (updatedBV) {
                    bateriaVirtual.pes_percentatge = updatedBV.percentage;
                    // ATENTION: pes, total_descompte no estan actualitzats, pot generar bug si els vols fer servir ja que no estaran ben sync.
                } else {
                    // Handle the case where no matching item is found in bateriesVirtualsMap.
                    console.warn(`No matching item found for bateria_id ${bateriaVirtual.id}`);
                }
            }
        }
        dispatch(setContractItems(syncedItems));
    }
}

export function fetchContactRequest(initial) {
    const message = (initial)?null:"Fetching messages";
    return {
        type: FETCH_CONTACT_REQUEST,
        payload: {
            message,
        },
    };
}

export function fetchContacts(token, initial=false) {
    return (dispatch) => {
        dispatch(fetchContactRequest(initial));
        data_fetch_api_resource(token, "contracts/")
            .then(parseJSON)
            .then(response => {
                dispatch(receiveContact(response.data, initial));
            })
    };
}

export function receiveContact(data, initial) {
    const message = (initial)?null:"Refreshing messages";
    return {
        type: RECEIVE_CONTACT,
        payload: {
            data,
            message,
        },
    };
}

export function receiveContactError(error) {
    const message = "Error while loading messages (" + error + ")";
    return {
        type: RECEIVE_CONTACT_ERROR,
        payload: {
            message,
        },
    };
}



export function newContactRequest() {
    return {
        type: CREATE_CONTACT_REQUEST,
    };
}

export function newContact(token, data) {
    return (dispatch) => {
        dispatch(newContactRequest());
        data_create_api_resource(token, "crm/", data )
            .then(parseJSON)
            .then(response => {
                if (response.result.was_created)
                    dispatch(receiveNewContact(response.result));
                else
                    dispatch(receiveNewContactKO({
                            status: 403,
                            statusText: 'Invalid token',
                            statusType: "warning",
                    }));

            })
            .catch(error => {
                    dispatch(receiveNewContactKO({
                            status: 404,
                            statusText: 'There was an error creating a new message',
                            statusType: "warning",
                    }));
                if (error.status === 401) {
                    dispatch(logoutAndRedirect(error));
                }
            });
    };
}

export function receiveNewContact(data) {
    return {
        type: CREATE_CONTACT_OK,
        payload: {
            data,
            statusText: "Message sended correctly",
            statusType: "info",
        },
    };
}

export function receiveNewContactKO(error) {
    return {
        type: CREATE_CONTACT_KO,
        payload: {
            status: (error.status === undefined) ? "403" : error.status,
            statusText: (error.statusText === undefined) ? "The provided credentials are not correct" : error.statusText,
            statusType: "danger",
        },
    };
}

