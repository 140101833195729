import React, { useState } from "react";
import _ from "lodash";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { data_create_api_resource } from "../../utils/http_functions";
import { locale_code } from "../../constants/i18n";
import { i18n } from "../../config";
import { b64toBlob } from "../../utils/misc";
import { LoadingAnimation } from '../LoadingAnimation';

import { CircularProgress, Button } from '@material-ui/core';

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const handleServerResponse = async (response) => {
    const { invoice } = props;

    if (response.error) {
      // Show error from server on payment form
      console.log("Response error", response.error);
      setSubmitting(false);
    } else if (response.requires_action) {
      // Use Stripe.js to handle the required card action
      const { error: errorAction, paymentIntent } =
        await stripe.handleCardAction(response.payment_intent_client_secret);
      if (errorAction) {
        setPaymentComplete(false)
        // Show error from Stripe.js in payment form
        console.log("Error action", errorAction);
        setSubmitting(false);
      } else {
        setPaymentComplete(true)
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        const serverResponse = await data_create_api_resource(
          null,
          `payments/${invoice.number}/`,
          {
            payment_intent_id: paymentIntent.id,
          }
        );
        console.log("ServerResponse", serverResponse);
        handleServerResponse(serverResponse.data);
      }
    } else {
      if (response.receipt) {
        let blob = b64toBlob(response.receipt, "application/pdf");
        let blobUrl = URL.createObjectURL(blob);

        let tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', 'invoice_payment.pdf');
        tempLink.click();
      }
      console.log("Success!");
      setSubmitting(false);
      props.onSuccess();
    }
    setPaymentComplete(false)
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const { invoice } = props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    // Use elements.getElement to get a reference to the mounted Element.
    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (error) {
      console.log("Error", error);
      alert(error.message);
      setSubmitting(false);
    } else {
      // Send paymentMethod.id to your server (see Step 2)
      try {
        const response = await data_create_api_resource(
          null,
          `payments/${invoice.number}/`,
          {
            payment_method_id: paymentMethod.id,
            amount: invoice.amount_debt,
          }
        );
        handleServerResponse(response.data);
      } catch (e) {
        setSubmitting(false);
        console.log("Error", e);
        alert(e.message);
        setSubmitting(false);
        setPaymentComplete(false)
      }
    }
  };

  const { invoice, onCancel } = props;
  const localizedDebt = invoice.amount_debt.toLocaleString(locale_code);

  return (
    <div className="checkout">
      { submitting ? (
          <div style={{ minHeight: "145px" }}>
            <LoadingAnimation/>
            { paymentComplete &&
              <div>
                {i18n.t('common:text.payment_wait_recipe')}
              </div>
            }
          </div>
        ):(
          <div>
            <div style={{ margin: "1em", marginBottom: "3em" }}>
              <CardElement options={{ hidePostalCode: true }} />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <Button
                color={'primary'}
                variant={'text'}
                onClick={onCancel}
                style={{ marginTop: "5px", marginLeft: "5px", marginRight: "5px" }}
                disabled={submitting}
              >
                {i18n.t('common:text.payment_form_cancel')}
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={handleSubmit}
                style={{ marginTop: "5px", marginLeft: "5px", marginRight: "5px" }}
                disabled={submitting}
              >
                {submitting ? <CircularProgress size={25}/> : null}
                {_.template(i18n.t('common:text.payment_form_pay'))({
                  amount: `${localizedDebt} €`,
                })}
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default PaymentForm;
