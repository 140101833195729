import { Chip, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import useTypes from "./useTypes";

const SelectFilter = ({defaultInputType, resetValue, categoryName, handleFilterValueChange, additionalData}) => {  
  const { selectTypes: types } = useTypes();
  const [inputType, setInputType] = useState(types[defaultInputType]); 
  const [innerValue, setInnerValue] = useState(types[defaultInputType].defaultValue);

  const handleValueChange = (newValue = innerValue, type = inputType) => {
    setInnerValue(newValue);
    handleFilterValueChange(newValue, type.render);
  }

  useEffect(() => {
    handleValueChange(inputType.defaultValue);
  }, [categoryName, resetValue]);

  return (
    <Grid item xs>
      <Autocomplete 
        multiple={typeof additionalData[0] !== "object" }
        options={additionalData}
        getOptionLabel={(option) => option?.label ?? ""}
        getOptionSelected={(option, selected) => option.title === selected.title}
        renderInput={(params) => <TextField {...params} label={inputType.title} variant="outlined" size="small"/>}
        onChange={(event, newValue) => handleValueChange(newValue)}
        disableClearable={innerValue !== null}
        value={innerValue}
        renderTags={(value, getTagProps) =>
          innerValue.map((option, index) => {
            return (
              <Chip 
                label={typeof option === "object" ? option.label : option } 
                color="primary" 
                {...getTagProps({ index })} 
                size="small" 
                style={{marginTop: 0, marginBottom: 0}} 
              />
          )})
        }
      />
    </Grid>
  );
}

export default SelectFilter;