import { randomString, generateHash } from './misc';
import crypto from "crypto";
import * as jose from "jose";
import Cookies from "js-cookie";


export function store_token(token) {
  Cookies.set('token', token, { secure: true, sameSite: 'Lax' } );
}

export function retrieve_token() {
    return Cookies.get('token');
}

export function remove_token() {
    Cookies.remove('token');
}

// ----------------------------------------------------------------------------
// OpenID Connect
// ----------------------------------------------------------------------------

export function store_openid_token(token) {
    Cookies.set('openid_token', token, { secure: true, sameSite: 'Lax' });
}

export function retrieve_openid_token() {
    return Cookies.get('openid_token');
}

export function remove_openid_token() {
    Cookies.remove('openid_token');
}

export async function generateAndSaveState(statePayload) {
  // Per generar una secret key fes córrer: openssl rand -base64 32
  const key = crypto.randomBytes(32).toString('hex');
  const secret = new TextEncoder().encode(key)
  const jwt = await new jose.SignJWT(statePayload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(secret)
  
  Cookies.set('openid_key', key, { secure: true, sameSite: 'Lax' });

  return jwt;
}

export const verifyState = async (state) => {
  const key = Cookies.get('openid_key');
  const secret = new TextEncoder().encode(key);
  const verifiedJWT = await jose.jwtVerify(state, secret);
  
  return verifiedJWT;
}

export function generateAndSaveNonce() {
    // Get random string
    const nonce = randomString();
    // Save nonce to Cookies
    Cookies.set('openid_nonce', nonce, { secure: true, sameSite: 'Lax' });

    // Return hash of generated nonce
    return generateHash(nonce);
}

export function getNonce() {
    return Cookies.get('openid_nonce');
}

export function isNonceValid(nonceHash) {
    // Retrieve nonce from browser cookies
    const storedNonce = Cookies.get('openid_nonce');
    // Generate a hash
    const storedNonceHash = generateHash(storedNonce);
    
    // Compare if stored nonce is the same of received nonce
    return storedNonceHash === nonceHash;
}

export function clearKey() {
    Cookies.remove('openid_key');
}

export function clearNonce() {
    Cookies.remove('openid_nonce');
}