import React from 'react';
import TextFieldReadOnly from "../TextFieldReadOnly";
import { connect } from 'react-redux';
import {data_update_api_resource} from "../../utils/http_functions";
import { red } from "@material-ui/core/colors";

import { CircularProgress, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const STATUS = {
  INIT: 0,
  EDITING: 1,
  SENDING: 2
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  contract: state.contracts.view_contract
});

class TextFieldEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: STATUS.INIT,
      value: this.props.value,
      errors: {}
    };
  }

  onClickEdit = async (e) => {
    e.preventDefault();
    const { field } = this.props;
    if (this.state.status === STATUS.INIT) {
      this.setState({status: STATUS.EDITING});
    } else if (this.state.status === STATUS.EDITING) {
      this.setState({status: STATUS.SENDING});
      const { token, contract } = this.props;
      const value = {};
      value[field] = this.state.value;
      try {
        await data_update_api_resource(
          token,`contracts/${contract.name}/notification/${field}/`, value
        );
        if (this.props.onUpdated) {
          this.props.onUpdated(this.state.value);
        } else {
          this.setState({status: STATUS.INIT});
        }
      } catch (e) {
        if (e.response && e.response.status === 400 && e.response.data.errors) {
          this.setState({
            errors: e.response.data.errors,
            status: STATUS.EDITING
          })
        } else {
          this.setState({status: STATUS.INIT});
        }
      }

    }
  };

  render() {
    const editing = this.state.status === STATUS.EDITING;
    const sending = this.state.status === STATUS.SENDING;
    const label = editing ? "Guardar" : "Completar";
    const errorText = this.state.errors[this.props.field];

    return (

      <TextFieldReadOnly {...this.props}>
        {this.state.status >= STATUS.EDITING  &&
          <form onSubmit={this.onClickEdit} style={{display: 'inline'}}>
            <input size={25} autoFocus style={{padding: '5px'}} onChange={(e) => this.setState({value: e.target.value})} disabled={sending} value={this.state.value}/>
          </form>
        }
        <Button
          color={'primary'}
          variant={'text'}
          disabled={sending}
          title={label}
          onClick={this.onClickEdit}
        >
          {sending ? <CircularProgress size={25} /> : null}
          {label}
        </Button>
        {editing && 
          <Button
            variant={'text'}
            style={{ minWidth: '10px' }}
            onClick={() => this.setState({ status: STATUS.INIT, errors: {} })}
          >
            {<Close />}
          </Button>
        }
        {errorText && editing &&
          <div style={{ padding: '8px', color: red[500] }}>{errorText}</div>
        }
      </TextFieldReadOnly>
    );
  }

}

export default connect(mapStateToProps, {})(TextFieldEdit);
