import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useEffect, useState } from 'react';

const StyledSkeleton = ({children, style, ...rest}) => {
  const [height, setHeight] = useState(Math.floor(Math.random() * 200) + 100 );
  
  useEffect(() => {
    const i = setInterval(() => {
      setHeight(Math.floor(Math.random() * 200) + 100 );
    }, 2000);

    return () => clearInterval(i);
  }, []);

  return <Skeleton {...rest} style={{...style, transition: "height 1s", height: height}}>{children}</Skeleton>;
}

const ChartSkeleton = () => {
  return (
    <div style={{height: "525px", width: "100%"}}>
      <Grid container justifyContent='space-evenly' alignItems='flex-end' wrap="nowrap" style={{height: "410px"}}>
        {Array.from(Array(32)).map((el, idx) => {
            return <Grid item key={idx}> 
              <StyledSkeleton variant='rect' animation={false} style={{width: "1.25vw", padding: "2px"}} />
            </Grid>
          })
        }
      </Grid>
      <div style={{marginTop: "25px", paddingLeft: "1.25vw", paddingRight: "1.25vw"}}>
        <Skeleton variant='rect' animation={false} height={35}/>
      </div>
      <Grid container spacing={1} justifyContent="center" wrap="nowrap" style={{marginTop: "10px"}}>
        <Grid item>
          <Skeleton variant='rect' animation={false} height={20} width={75}/>
        </Grid>
        <Grid item>
          <Skeleton variant='rect' animation={false} height={20} width={75}/>
        </Grid>
        <Grid item>
          <Skeleton variant='rect' animation={false} height={20} width={75}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default ChartSkeleton;