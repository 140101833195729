import jwtDecode from 'jwt-decode';

import { createReducer } from '../utils/misc';

import {
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGIN_USER_REQUEST,
    LOGIN_OPENID_SUCCESS,
    LOGIN_OPENID_FAILURE,
    LOGIN_OPENID_REQUEST,
    LOGIN_CLIENTID_SUCCESS,
    LOGIN_CLIENTID_FAILURE,
    LOGIN_CLIENTID_REQUEST,
    LOGIN_REFRESH_REQUEST,
    LOGIN_REFRESH_SUCCESS,
    LOGIN_REFRESH_FAILURE,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_REQUEST,
    REGISTER_USER_FAILURE,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    RECOVER_USER_SUCCESS,
    RECOVER_USER_FAILURE,
    RECEIVE_REGISTER_SPECS,
    RECEIVE_REGISTER_SPECS_KO,
    FETCH_REGISTER_SPECS_REQUEST,
    VERSION,
    VERSION_PR,
} from '../constants/index';

const initialState = {
    token: null,
    userName: null,
    userRoles: null,
    userGroups: null,
    userImage: null,
    isAuthenticated: false,
    isAuthenticating: false,
    statusText: null,
    isRegistering: false,
    isRegistered: false,
    registerStatusText: null,
    version: VERSION,
    version_pr: VERSION_PR,
};

export default createReducer(initialState, {
    [LOGIN_USER_REQUEST]: (state) =>
        Object.assign({}, state, {
            isAuthenticating: true,
            statusText: null,
        }),
    [LOGIN_USER_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            token: payload.token,
            userName: jwtDecode(payload.token).email,
            userRoles: jwtDecode(payload.token).roles,
            userGroups: jwtDecode(payload.token).groups,
            userImage: jwtDecode(payload.token).image,
            statusText: 'You have been successfully logged in.',
        }),
    [LOGIN_USER_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: `${payload.statusText}`,
            statusType: payload.statusType,
        }),
    [LOGIN_OPENID_REQUEST]: (state) =>
        Object.assign({}, state, {
            isAuthenticating: true,
            statusText: null,
        }),
    [LOGIN_OPENID_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            type: 'oidc',
            token: payload.token,
            openIdToken: payload.openIdToken,
            userName: jwtDecode(payload.token).email,
            userRoles: jwtDecode(payload.token).roles,
            userGroups: jwtDecode(payload.token).groups,
            userImage: jwtDecode(payload.token).image,
            statusText: 'You have been successfully logged in.',
        }),
    [LOGIN_OPENID_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            openIdToken: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: `${payload.statusText}`,
        }),
    [LOGIN_CLIENTID_REQUEST]: (state) =>
        Object.assign({}, state, {
            isAuthenticating: true,
            statusText: null,
        }),
    [LOGIN_CLIENTID_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            type: 'clientId',
            token: payload.token,
            userName: jwtDecode(payload.token).email,
            userRoles: jwtDecode(payload.token).roles,
            userGroups: jwtDecode(payload.token).groups,
            userImage: jwtDecode(payload.token).image,
            statusText: 'You have been successfully logged in.',
        }),
    [LOGIN_CLIENTID_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: `${payload.statusText}`,
            statusType: payload.statusType,
        }),
    [LOGIN_REFRESH_REQUEST]: (state) =>
        Object.assign({}, state, {
            isAuthenticating: true,
            statusText: null,
        }),
    [LOGIN_REFRESH_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            token: payload.token,
            userName: jwtDecode(payload.token).email,
            userRoles: jwtDecode(payload.token).roles,
            userGroups: jwtDecode(payload.token).groups,
            userImage: jwtDecode(payload.token).image,
            statusText: 'User session has been successfully updated.',
            statusType: payload.statusType,
        }),
    [LOGIN_REFRESH_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: `${payload.statusText}`,
            statusType: payload.statusType,
        }),
    [LOGOUT_REQUEST]: (state) =>
        Object.assign({}, state, {
            isLoggingOut: true,
            statusText: null,
        }),
    [LOGOUT_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isLoggingOut: false,
            isAuthenticated: false,
            type: null,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: null,
            registerStatusText: null,
        }),
    [LOGOUT_FAILURE]: (state, payload) =>
    Object.assign({}, state, {
        isLoggingOut: false,
        statusText: `${payload.statusText}`,
        statusType: payload.statusType,
    }),
    [REGISTER_USER_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: true,
            isRegistering: false,
            token: payload.token,
            userName: jwtDecode(payload.token).email,
            userRoles: jwtDecode(payload.token).roles,
            userGroups: jwtDecode(payload.token).groups,
            userImage: jwtDecode(payload.token).image,
            registerStatusText: 'You have been successfully logged in.',
        }),
    [REGISTER_USER_REQUEST]: (state) =>
        Object.assign({}, state, {
            isRegistering: true,
        }),
    [REGISTER_USER_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            registerStatusText: `${payload.statusText}`,
            statusType: `${payload.statusType}`,
        }),
    [RECOVER_USER_SUCCESS]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: 'If the provided account is valid, you will receive a recovery email.',
        }),
    [RECOVER_USER_FAILURE]: (state, payload) =>
        Object.assign({}, state, {
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRoles: null,
            userGroups: null,
            userImage: null,
            statusText: `Recovery Error: ${payload.status} ${payload.statusText}`,
            statusType: `${payload.statusType}`,
        }),
    [RECEIVE_REGISTER_SPECS]: (state, payload) =>
        Object.assign({}, state, {
            data: payload.data,
            isFetching: false,
            loaded: true,
            error: false,
        }),
    [FETCH_REGISTER_SPECS_REQUEST]: (state) =>
        Object.assign({}, state, {
            isFetching: true,
        }),
    [RECEIVE_REGISTER_SPECS_KO]: (state, payload) =>
        Object.assign({}, state, {
            isFetching: false,
            data: payload.data,
            loaded: false,
            error: true,
        }),
});
