import { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from "@material-ui/lab";

import NumericFilter from "./NumericFilter";
import StringFilter from "./StringFilter";
import AutocompleteFilter from "./AutocompleteFilter";
import SelectFilter from "./SelectFilter";
import DatePickerFilter from "./DatePickerFilter";

import useTypes from "./useTypes";
import { useTranslation } from "react-i18next";

const useFilter = () => {
  const types = useTypes();

  const createFilter = (idx, value, category, type) => {
    const renderFunc = types[category.type+"Types"][type].render;
    const filterVals = renderFunc(value, category.renderText, category.searchField);
    return {render: filterVals.render, search: filterVals.search, active: true}
  }

  return {createFilter};
};

const FilterCreator = ({categories, onCreate}) => {
  const { t } = useTranslation();

  const [category, setCategory] = useState(categories[0]);
  const [value, setValue] = useState(null);
  const [resetValue, setResetValue] = useState(false);

  const handleFilterValueChange = (newValue, renderFunc) => {
    setValue(renderFunc(newValue, category.renderText, category.searchField, category?.units));
    if (resetValue) {
      setResetValue(false);
    }
  }

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory); 
  }

  const handleCreate = () => {
    onCreate({render: value.render, search: value.search, active: true});
    setResetValue(true);
  }

  const getSelectedFilter = () => {
    switch (category.type) {
      case "numeric": 
        return <NumericFilter 
          defaultInputType="exact"
          resetValue={resetValue}
          categoryName={category?.renderText}
          categoryUnits={category.units}
          handleFilterValueChange={handleFilterValueChange} 
          handleCreate={handleCreate}
        />;

      case "text": 
        return <StringFilter 
          defaultInputType="contains"
          resetValue={resetValue}
          categoryName={category?.renderText}
          handleFilterValueChange={handleFilterValueChange} 
          handleCreate={handleCreate}
        />;

      case "select": 
        return <SelectFilter 
          defaultInputType="contains"
          resetValue={resetValue}
          additionalData={category?.additionalData}
          categoryName={category?.renderText}
          handleFilterValueChange={handleFilterValueChange} 
        />;

      case "autocomplete": 
        return <AutocompleteFilter 
          defaultInputType="contains"
          resetValue={resetValue}
          additionalData={category?.additionalData}
          categoryName={category?.renderText}
          handleFilterValueChange={handleFilterValueChange} 
        />;
        
      case "date": 
        return <DatePickerFilter 
          defaultInputType="between"
          resetValue={resetValue}
          additionalData={category?.additionalData}
          categoryName={category?.renderText}
          handleFilterValueChange={handleFilterValueChange} 
        />;
      
    
      default: 
        return <></>;
    }
  }

  return (
    <Grid container direction="row" justifyContent="center" spacing={1}>

      <Grid item xs={12} md={3}>
        <Autocomplete
          id="combo-box-demo"
          options={categories}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, selected) => option.category === selected.category}
          renderInput={(params) => <TextField {...params} label={t("common:category")} variant="outlined" size="small"/>}
          disableClearable={category !== null}
          value={category}
          onChange={(event, newCategory) => handleCategoryChange(newCategory)}
        />
      </Grid>

      <Grid item container xs={12} md={7}>
        {getSelectedFilter()}
      </Grid>

      <Grid item container xs={12} md={2}>
        <Button 
          color="primary" 
          variant="contained" 
          fullWidth 
          style={{borderRadius: "5px", height: "fit-content"}} 
          startIcon={<AddIcon />} 
          disabled={!value}
          onClick={() => handleCreate()}
        >
          {t("common:text.new")}
        </Button>
      </Grid>

    </Grid>
  );
};

export { FilterCreator, useFilter };