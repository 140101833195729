import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/contracts';
import { i18n } from "../config";

import AltContract from './AltContract';

import { debug } from '../utils/debug';

import { LoadingAnimation } from './LoadingAnimation';
import { Box, Typography } from '@material-ui/core';

function mapStateToProps(state) {
    return {
        data: state.contracts,
        contract: state.contracts.view_contract,
        token: state.auth.token,
        loaded: state.contracts.loaded,
        isFetching: state.contracts.isFetching,
        error: state.contracts.error,
        errorMessage: state.contracts.data,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

class AltContractView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            no_contract: false
        };
    }

    componentDidMount() {
        if (_.isEmpty(this.props.data.items)) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.loaded && !prevProps.loaded) {
            const contract = this.props.contract ?
                this.props.contract :
                this.props.data.items[0];
            contract ?
                this.props.viewContract(contract)
            :
                this.setState({no_contract: true})

        }
    }

    fetchData(initial=true, offset=0, filter=null) {
        const token = this.props.token;
        this.props.fetchContracts(token, initial, offset, filter);
    }

    render() {
        return (
            <div>
                {
                this.state.no_contract?
                (
                    <Typography variant={'h6'}>
                        {i18n.t('common:text.contact_view_no_contracts')}
                    </Typography>
                )
                :
                    (this.props.loaded && this.props.contract)?
                        <div>
                            <Box mb={2}>
                                <Typography variant={'h4'}>
                                    {i18n.t('common:text.contract_title')}
                                </Typography>
                            </Box>
                            <AltContract/>    
                            {debug(this.props.data)}
                        </div>
                    :
                        <div>
                            <LoadingAnimation />
                        </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AltContractView);

AltContractView.propTypes = {
    fetchContracts: PropTypes.func,
    loaded: PropTypes.bool,
    data: PropTypes.any,
    token: PropTypes.string,
};
