import React from "react";
import { i18n } from "../../config";

import {Select, FormControl, FormHelperText, InputLabel, MenuItem} from "@material-ui/core"

const ResidenceTypeSelect = ({
  input: { onChange, value },
  onUpdate,
  meta,
  label,
  ...rest
}) => (
  <>
    <FormControl style={{width: "100%"}}>
      <InputLabel id="tipus-vivenda-label" error={meta.error}>{i18n.t("common:text.contractation_supply_type")}*</InputLabel>
      <Select
        onChange={(event) => {
          onChange(event.target.value);
          if (typeof onUpdate === "function") {
            onUpdate(event.target.value);
          }
        }}
        {...rest}
        value={value}
      >
        <MenuItem key={1} value={"habitual"}>{i18n.t("common:text.contractation_habitual_supply")}</MenuItem>
        <MenuItem key={2} value={"second"}>{i18n.t("common:text.contractation_second_supply")}</MenuItem>
        <MenuItem key={3} value={"business"}>{i18n.t("common:text.contractation_business_supply")}</MenuItem>
      </Select>
    </FormControl>
    { meta.error && meta.touched &&
      <FormHelperText error={true} id="tipus-vivenda-error-text">
        {meta.error}
      </FormHelperText>
    }
  </>
);

export default ResidenceTypeSelect;
