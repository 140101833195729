import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { StyleVars } from "../../settings";
import _ from "lodash";

const defaultTheme = createTheme();

console.log(defaultTheme);

const customBreakpoints = {
  phone: StyleVars.phone,
  tablet: StyleVars.tablet,
  desktop: StyleVars.desktop,
  largeDesktop: StyleVars.largeDesktop
};

const themeBreakpoints = {
  breakpoints: createBreakpoints({
    values: customBreakpoints
  })
};

const themeOptions = {
  typography: {
    fontFamily: [
      StyleVars.fontBase
    ].join(','),
    subtitle1: {
      fontSize: "1rem",
      fontWeight: "bold"
    }
  },
  palette: {
    primary: {
      main: StyleVars.colorPrimary,
      contrastText: StyleVars.colorPrimaryText,
    },
    secondary: {
      main: StyleVars.colorSecondary,
      contrastText: StyleVars.colorSecondaryText,
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: StyleVars.borderRadius,
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: StyleVars.borderRadius,
      }
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: StyleVars.colorPrimary
        },
        '&$checked + $track': {
          backgroundColor: StyleVars.colorPrimary
        }
      },
      track: {
        backgroundColor: StyleVars.colorSecondary,
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: StyleVars.colorPrimary
        },
      }
    },
    MuiAppBar: {
      root: {
        // Els custom breakpoints sembla que no funcionen en MUI 4. S'ha de provar més endavant a MUI 5
        [themeBreakpoints.breakpoints.up('992')]: {
          // defaultTheme.overrides.MuiAppBar.root.height
          height: StyleVars.appBarHeightMd
        },
        [themeBreakpoints.breakpoints.down('992')]: {
          height: StyleVars.appBarHeightSm
        }
      },
      colorPrimary: {
        // https://stackoverflow.com/questions/56224684/material-ui-v4-appbar-wont-change-theme
        // defaultTheme.overrides.MuiAppBar.colorPrimary.backgroundColor
        backgroundColor: StyleVars.appBarBgColor
      },
    }
  }
};

const theme = createTheme(themeOptions);

export default theme;