import { Typography, Paper, Grid, Button, Box } from "@material-ui/core";
import { LocalOffer, Help}  from '@material-ui/icons';
import RichContent from "./RichContent";

import { CopyElement } from "./CopyElement";
import { useTranslation } from "react-i18next";

export const PublicityBanner = ({
  code,
  campaign,
  code_url
}) => {

  const { t } = useTranslation();

  const moreInfo = () => {
    window.open(campaign.url, '_blank').focus();
  }

  return <Paper 
      className="pbanner-container"
      elevation={5}
    >
      <Grid container style={{padding: "5px"}} direction={"column"} justifyContent={"center"}>

        <Grid item>
          <Box m={2} display={"flex"} alignItems="center" justifyContent="center" className="pbanner-title">
            <LocalOffer style={{padding: "5px", paddingRight: "10px"}} />
            <Typography variant="h5" style={{fontWeight: 600}}>{campaign.name.toLocaleUpperCase()}</Typography>
          </Box>
        </Grid>

        <Grid item container justifyContent="center" style={{padding: "0 25px 5px 25px"}} className="pbanner-description">
          <RichContent 
            style={{fontWeight: 500}} 
            content={campaign.description || ''} 
            html={true}
          />
        </Grid>
      
        <Grid item container xs={12} style={{padding: "10px"}} justifyContent="center">
          <Grid item xs={10} sm={12} md={10} lg={6}>
            <CopyElement labelText={code_url} value={code_url}/>
          </Grid>
        </Grid>
        
        {campaign.url && <Grid item style={{paddingBottom: "10px"}} xs={12}>
          <Box display="flex" justifyContent="center">
            <Button startIcon={<Help />} onClick={moreInfo} className="pbanner-moreInfo">
              {t('common:text.coupon_more_info')}
            </Button>
          </Box>
        </Grid>}

      </Grid>

    </Paper>
}