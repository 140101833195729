/* eslint camelcase: 0, no-underscore-dangle: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { TextField, Button, Box, Typography, Grid } from "@material-ui/core";
import * as actionCreators from '../actions/password';
import { validateUser } from '../utils/misc';
import { navigationHistoryGoBack } from '../utils/http_functions';

import { Notification } from './Notification';

import { i18n } from "../config";

function mapStateToProps(state) {
    return {
        isAuthenticating: state.auth.isAuthenticating,
        token: state.auth.token,
        message_text: state.password.message_text,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

const styles = {
    main: {
        width: '100%',
        textAlign: 'center',
        display: 'inline-block',
    },
    logo: {
        paddingLeft: '15%',
        paddingRight: '15%',
        paddingTop: '20px',
        paddingBottom: '20px',
    }
};

class ChangePasswordView extends React.Component {

    constructor(props) {
        super(props);
        const redirectRoute = '/login';
        this.state = {
            currentPassword: '',
            newPassword: '',
            currentPassword_error_text: null,
            password_error_text: null,
            passwordValidation_error_text: null,
            redirectTo: redirectRoute,
            disabled: true,
            message_text: null,
            message_open: false,
        };
    }

    isDisabled() {
        let email_is_valid = false;
        let password_is_valid = false;

        if (this.state.currentPassword === '') {
            this.setState({
                currentPassword_error_text: null,
            });
        } else if (validateUser(this.state.currentPassword)) {
            email_is_valid = true;
            this.setState({
                currentPassword_error_text: null,
            });

        } else {
            this.setState({
                currentPassword_error_text: i18n.t('common:text.change_password_view_message_error_key_not_valid'),
            });
        }


        // Disable reviewing for empty fields
        if (this.state.newPassword === '' || !this.state.newPassword) {
            this.setState({
                message_open: false,
                password_error_text: null,
                passwordValitaion_error_text: null,
            });

        // Ensure new passwd len >= 6
        } else if (this.state.newPassword.length >= 6) {
            password_is_valid = true;
            this.setState({
                message_open: false,
                password_error_text: null,
                passwordValidation_error_text: null,
            });

            // Confirm bot new passwd match
            if (this.state.newPassword !== this.state.newPasswordValidation) {
                password_is_valid = false;
                this.setState({
                    message_open: false,
                    passwordValidation_error_text: i18n.t('common:text.change_password_view_message_error_new_do_not_match'),
                });
            }

        } else {
            this.setState({
                message_open: false,
                password_error_text: i18n.t('common:text.change_password_view_message_error_new_key_invalid_length'),
            });
        }

        
        const must_be_disabled = (email_is_valid && password_is_valid)? false : true;
        this.setState({
            message_open: false,
            disabled: must_be_disabled,
        });

    }

    changeValue(e, type) {
        const value = e.target.value;
        const next_state = {};

        next_state[type] = value;
        next_state.message_open = false;
        this.setState(next_state, () => {
            this.isDisabled();
        });
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (!this.state.disabled) {
                this.changePassword(e);
            }
        }
    }

    changePassword(e) {
        e.preventDefault();

        const token = this.props.token;

        let next="/";

        if (
          "" !== this.props.location.search &&
          i18n.t('common:url.next') in this.props.location.search.includes(i18n.t('common:url.next'))
        ) {
          let next_param = this.props.location.search.split(i18n.t('common:url.next') + "=");
          next_param = next_param[next_param.length - 1];
          next = next_param ? next_param : "/";
        }

        this.setState({
            message_open: true,
            redirectTo: next,
        });

        this.props.changePassword(token, this.state.currentPassword, this.state.newPassword, next);
    }

    cancel(e) {
        e.preventDefault();
       	navigationHistoryGoBack();
    }

    render() {
        return (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            onKeyPress={(e) => this._handleKeyPress(e)}
          >
            <Grid item>
              <Notification
                message={this.props.message_text}
                open={this.state.message_open}
              />

              <div style={styles.main}>
                <form>
                  <div>
                    <Box mb={2}>
                      <Typography variant="h4" noWrap>
                        {i18n.t('common:text.change_password_view_title')}
                      </Typography>
                    </Box>
                    {false && this.props.statusText && (
                      <div
                        className={
                          "alert alert-info alert-" + this.props.statusType
                        }
                      >
                        {this.props.statusText}
                      </div>
                    )}

                    <div>
                      <TextField
                        placeholder={
                          i18n.t('common:text.change_password_view_field_currentPassword_hint')
                        }
                        label={
                          i18n.t('common:text.change_password_view_field_currentPassword_title')
                        }
                        type="password"
                        error={!!this.state.currentPassword_error_text}
                        helperText={this.state.currentPassword_error_text}
                        onChange={(e) => this.changeValue(e, "currentPassword")}
                      />
                    </div>
                    <div>
                      <TextField
                        placeholder={
                          i18n.t('common:text.change_password_view_field_newPassword_hint')
                        }
                        label={
                          i18n.t('common:text.change_password_view_field_newPassword_title')
                        }
                        type="password"
                        error={!!this.state.password_error_text}
                        helperText={this.state.password_error_text}
                        onChange={(e) => this.changeValue(e, "newPassword")}
                      />
                    </div>
                    <div>
                      <TextField
                        placeholder={
                          i18n.t('common:text.change_password_view_field_newPassword_again_hint')
                        }
                        label={
                          i18n.t('common:text.change_password_view_field_newPassword_again_title')
                        }
                        type="password"
                        error={!!this.state.passwordValidation_error_text}
                        helperText={this.state.passwordValidation_error_text}
                        onChange={(e) =>
                          this.changeValue(e, "newPasswordValidation")
                        }
                      />
                    </div>
                    <Box mt={4}>
                      <Grid container spacing={2}>
                        <Grid key={0} item xs>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={this.state.disabled}
                            onClick={(e) => this.changePassword(e)}
                          >
                            {i18n.t('common:text.change_password_view_button_send')}
                          </Button>
                        </Grid>
                        <Grid key={1} item xs>
                          <Button
                            variant="contained"
                            onClick={(e) => this.cancel(e)}
                          >
                            {i18n.t('common:text.change_password_view_button_cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);

ChangePasswordView.propTypes = {
    changePassword: PropTypes.func,
    statusText: PropTypes.string,
    statusType: PropTypes.string,
};
