import React from 'react';
import { LinearScale } from '@material-ui/icons';
import { Tooltip, withStyles, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeColorDarker } from '../../utils/colors'

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: "black",
    maxWidth: 400,
    border: '2px solid grey',
    fontSize: "12pt",
    padding: 15,
    fontWeight: "400",
  },
}))(Tooltip);

export const ChartLegend = ({ 
  payload,
  components,
  toggleBarVisibility,
  handleLegendMouseEnter,
  handleLegendMouseLeave,
  hover,
  fields,
}) => {
  const { i18n } = useTranslation();

  return (
    <Box mt={6} mb={2}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={8}
      >
        {
          payload.map((entry, index) => {
            if (entry.value === "total") {
              return (
                <Box
                  key={`item-${index}`}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => toggleBarVisibility(entry.value)}
                  onMouseEnter={() => handleLegendMouseEnter(entry.value)}
                  onMouseLeave={() => handleLegendMouseLeave()}
                >
                  <LinearScale
                    fontSize='small'
                    style={{color:fields[entry.value]?'black':'gray'}}
                  />
                  <Box
                    sx={{
                      color: fields[entry.value] ? 'black' : 'gray',
                      fontWeight: 500,
                      textTransform: 'capitalize',
                    }}
                  >
                    {entry.value}
                  </Box>
                </Box>
              )
            }

            return (
              <CustomTooltip
                key={`item-${index}`}
                placement="top"
                title={components[entry.value]?.address}
                onClick={() => toggleBarVisibility(entry.value)}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    borderColor:hover==entry.value?fields[entry.value]?makeColorDarker(entry.color, 80):'gray':'transparent',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: 4,
                    display: 'flex',
                    gap: 4,
                    padding: 4,
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onMouseEnter={() => {
                    handleLegendMouseEnter(entry.value);
                  }}
                  onMouseLeave={() => {
                    handleLegendMouseLeave();
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: fields[entry.value]?entry.color:'gray',
                      borderRadius: '4px',
                      height: '15px',
                      width: '15px',
                    }}
                  />
                  <Box
                    sx={{
                      color: fields[entry.value] ? 'black' : 'gray',
                      fontWeight: 500,
                    }}
                  >
                    {entry.value}
                  </Box>
                </Box>
              </CustomTooltip>
            )
          })
        }
      </Grid>
    </Box>
  );
}

