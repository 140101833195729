import React from "react";
import { Error } from '@material-ui/icons';
import { i18n } from "../config";

const DEFAULT_MESSAGE = (
  <div>
    {i18n.t('common:text.message_cant_do_operation')}
  </div>
);

const ErrorMessage = ({icon = <Error/>, children = DEFAULT_MESSAGE}) => {
  return (
    <div style={{display: 'flex'}}>
      <div style={{marginRight: '10px'}}>{icon}</div>
      {children || DEFAULT_MESSAGE}
    </div>
  );
};

export default ErrorMessage;
