import { Box, Grid, Input, InputAdornment, Paper, Slider, Typography } from "@material-ui/core";
import { Battery20, Battery30, Battery50, Battery60, Battery80, BatteryAlert, BatteryChargingFull } from '@material-ui/icons';
import { useTranslation } from "react-i18next";


/**
 * UI component(controlled component)
 *
 * Responsible for rendering the label, the slider and the input to modify percentages for batteria virtual
 */
const VirtualBatteryPercentageSlider = ({
	onChange,
	value,
	step,
	min,
	max,
	disabled,
}) => {
  const { i18n } = useTranslation();

  const handleSliderChange = (event, newValue) => {
    onChange(event, newValue);
  };

  const handleInputChange = (event) => {
    onChange(event, event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      onChange(undefined, 0);
    } else if (value > 100) {
      onChange(undefined, 100);
    }
  };

  const renderBatteryIcon = () => {
    if (value >= 100) return <BatteryChargingFull/>
    else if (value >= 80) return <Battery80/>
    else if (value >= 60) return <Battery60/>
    else if (value >= 50) return <Battery50/>
    else if (value >= 30) return <Battery30/>
    else if (value > 0) return <Battery20/>
    return <BatteryAlert/>
  }

  return (

    <Box>
      <Typography variant="h6">
        {i18n.t('common:text.virtual_battery_percentage')}
      </Typography>

      <Paper
        style={{
          padding: "1rem",
          overflowY: "auto",
          maxHeight: "35vh",
        }}
        variant="outlined"
      >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            aria-labelledby="input-slider" style={{verticalAlign: "bottom"}}
          />
        </Grid>
        <Grid item xs={2}>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: {step},
              min: {min},
              max: {max},
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                { renderBatteryIcon() }
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Paper>
    </Box>
  );
}

export default VirtualBatteryPercentageSlider;
